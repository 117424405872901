import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Col, Image, Row} from "react-bootstrap";

// Constants
import dropFieldClassNames from "../../../../utils/dropFieldClassNames";

// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";
import * as RealtimeConnection from "../../RealtimeConnection";

// Images
import downArrow from "../../../../assets/images/down-arrow.svg";
import upArrow from "../../../../assets/images/up-arrow.svg";

// Components
import DroppableField from "../../Common/DroppableField";


const BottomBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [id, setId] = useState(0)
	const [title, setTitle] = useState("")

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		setId(props.id)
	}, [props.id])

	useEffect(() => {
		setTitle(props.title)
	}, [props.title])

	const appendContainer = (coins) => {
		props.addContainer("BottomBlock", id, coins, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addContainer("BottomBlock", {
			id: id,
			coins: coins
		})
	}

	const removeContainer = (isDropToDelete) => {
		props.removeContainer("BottomBlock", id);

		// Update Socket
		RealtimeConnection.removeContainer("BottomBlock", {
			id: id,
			isDropToDelete: isDropToDelete
		})
	}

	const addCoin = (value) => {
		props.addCoin("BottomBlock", id, value, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addCoin("BottomBlock", {
			id: id,
			value: value
		})
	}

	const removeCoin = (index, isDropToDelete) => {
		props.removeCoin("BottomBlock", id, index, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.removeCoin("BottomBlock", {
			id: id,
			index: index,
			isDropToDelete: isDropToDelete
		})
	}

	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "BottomBlock",
			isDragging: isDragging,
			type: type,
			id: id
		}
		dataObj = { ...data, ...dataObj }

		window.socket.emit('item-dragging', dataObj);
	}

	const showContainerProperties = () => {
		props.openContainerProperties('FIELD', 'BottomBlock', id);
	}

	return (
		<div className="Remove-padding">
			<Row>
				<Col xs={8}>
					<div className="Bottom-block">
						<Row>
							<div className="text-column">
								<p className="Text-bold Text-size">{ title }</p>
							</div>
							<div className="field-column" onClick={showContainerProperties}>
								<DroppableField
									canEdit={canEdit}
									class={ dropFieldClassNames.BottomBlock }
									data={ props.bottomBlock[id] }
									isHorizontal={ true }
									appendContainer={ appendContainer }
									removeContainer={ removeContainer }
									addCoin={ addCoin }
									removeCoin={ removeCoin }
									isDraggingHandler={ isDraggingHandler }
								/>
							</div>
						</Row>
						{
							(id === 0) ?
								<Image src={ upArrow } className="Arrow-class" />
								:
								<Image src={ downArrow } className="Arrow-class" />
						}
					</div>
				</Col>
				<Col xs={4}></Col>
			</Row>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		bottomBlock: state.board.BottomBlock
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomBlock);