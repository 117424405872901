import React from "react";
import { useDrag } from 'react-dnd';

export default function MainDraggableBlackToken(props) {
	const [, drag] = useDrag({
		item: { type: 'BlackToken', value: props.value, count: props.count },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let droppedItem = monitor.getDropResult();
			if (droppedItem !== null && droppedItem.type !== undefined) {
				if (monitor.didDrop() && dropResult.count >= dropResult.value) {
					props.decreaseStarterKit("blackTokens", dropResult.value);
				}
			}
		}
	});

	const canDrag = (props.canEdit) ? drag : null
	return (
		<div className="tokenDraggable" ref={canDrag}>
			<div className="Token-container-draggable black">
				<div className="Token-value">{ props.value }</div>
				<div className="Token-text">venture capital</div>
			</div>
		</div>
	);
}