const startingKit = {
	coins: 60.5,
	tokens: 30,
	blackTokens: 0,
	pins: 2,
	factory: 1,
	machine: 1,
	production: {}
}

export default startingKit;