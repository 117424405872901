import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Col,
  Container,
  Image,
  Row,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";
import Popup from "reactjs-popup";
import moment from "moment";

import i18n from "../../i18n";

import Translate from "../../utils/Translate";

// Constants
import urls from "../../utils/urls";

// Dispatchers
import mapDispatchToProps from "../../reducers/dispatchers";
import * as RealtimeConnection from "./RealtimeConnection";

// Images
import logo from "../../assets/images/header-logo.png";
import zoomIn from "../../assets/images/zoom-in.png";
import zoomOut from "../../assets/images/zoom-out.png";
import chatIcon from "../../assets/images/chat-icon.png";
import logoutIcon from "../../assets/images/logout-icon.png";

// Components
import Properties from "../Properties";
import ChatComponent from "./ChatComponent";

let counterIntervalTimer = undefined;

const Header = (props) => {
  const [canEdit, setCanEdit] = useState(false);
  const [showBackStepBtn, setShowBackStepBtn] = useState(false);
  const [selectedLanguageTitle, setSelectedLanguageTitle] = useState("English");
  const [languages, setLanguages] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [teamName, setTeamName] = useState("Team-" + window.teamId);

  const [showPopup, setShowPopup] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [editTeamName, setEditTeamName] = useState(false);

  const [counter, setCounter] = useState(undefined);
  const [counterData, setCounterData] = useState({
    name: "",
    counterMinutes: 0,
    counterSeconds: 0,
  });

  useEffect(() => {
    fetchCounter();
  }, []);

  useEffect(() => {
    if (props.session.timer.duration !== undefined) {
      const counterTimer = { ...props.session.timer };

      if (props.session.timer.duration === 0) {
        setCounterData({
          name: counterData.name,
          counterMinutes: 0,
          counterSeconds: 0,
        });
        if (counterIntervalTimer) {
          clearInterval(counterIntervalTimer);
        }
      } else {
        fetchCounter();
      }
    }
  }, [props.session.timer]);

  useEffect(() => {
    if (props.session.teamName) {
      setTeamName(props.session.teamName);
    } else if (props.teamName) {
      setTeamName(props.teamName);
    }
  }, [props.teamName, props.session.teamName]);

  useEffect(() => {
    setCanEdit(props.canEdit);
  }, [props.canEdit]);

  useEffect(() => {
    setShowBackStepBtn(props.isAdmin ? true : false);
  }, [props.isAdmin]);

  useEffect(() => {
    if (languages.length === 0) {
      setLanguages(props.languages);
    }
  }, [props.languages]);

  useEffect(() => {
    const selectedLang = languages.filter(
      (lang) => lang.id === parseInt(localStorage.langId)
    );
    if (selectedLang.length > 0) {
      setSelectedLanguageTitle(selectedLang[0].name);
    }
  }, [languages]);

  useEffect(() => {
    props.toggleChat(openChat);
  }, [props.toggleChat, openChat]);

  useEffect(() => {
    let appContainer = document.getElementsByClassName("App");
    let kitContainer = document.getElementsByClassName("main-app-bg");

    appContainer[0].classList.add(`zoom-${zoomLevel}`);
    kitContainer[0].classList.add(`zoom-value-${zoomLevel}`);
    kitContainer[0].classList.add(`zoom-${zoomLevel}`);
  }, [zoomLevel]);

  useEffect(() => {
    if (props.properties.isFieldSelected) {
      setShowPopup(true);
    }
  }, [props.properties.isFieldSelected]);

  useEffect(() => {
    if (counter) {
      counterIntervalTimer = setInterval(() => {
        calculateCounter(counter);
      }, 1000);
    }
  }, [counter]);

  const fetchCounter = async () => {
    const result = await axios.post(urls.activeCounter, {
      id: window.sessionId,
    });

    let response = result.data;
    console.log(response);
    if (response.data) {
      console.log("HERE");
      //this.counterTimer = response.data
      calculateCounter(response.data);
    }
  };

  const calculateCounter = (timerData) => {
    const currentDate = moment().utc();
    const startDate = moment.utc(timerData.createdAt);
    const endDate = startDate
      .clone()
      .add(parseInt(timerData.duration), "minutes");

    const minutes = endDate.diff(currentDate);
    if (minutes > 0) {
      let minutesValue = 0;
      let secondsValue = 0;
      if (Math.ceil((minutes / 1000) % 60) === 60) {
        minutesValue = Math.ceil(minutes / 1000 / 60);
        secondsValue = 0;
      } else {
        if (minutes > 60000) {
          minutesValue = Math.ceil(minutes / 1000 / 60) - 1;
        } else {
          minutesValue = 0;
        }
        secondsValue = Math.ceil((minutes / 1000) % 60);
      }

      setCounterData({
        name: timerData.name,
        counterMinutes: minutesValue,
        counterSeconds: secondsValue,
      });
      if (!counter) {
        setCounter(timerData);
      } else if (counter.createdAt !== timerData.createdAt) {
        setCounter(timerData);
      }
    } else {
      setCounterData(undefined);
      if (counterIntervalTimer) {
        clearInterval(counterIntervalTimer);
      }
    }
  };

  const toggleChat = () => {
    setOpenChat(!openChat);
  };

  const changeLanguage = (language) => {
    if (localStorage.langId !== language) {
      localStorage.langId = language;
      i18n.changeLanguage(`${language}`);

      window.location.reload();
    }
  };

  const updateEditTeamName = () => {
    if (parseInt(props.session.year) === 0) {
      setEditTeamName(true);
    }
  };

  const logout = () => {
    props.logoutAction();
  };

  const stepBack = () => {
    // Update Socket
    RealtimeConnection.oneStepBack();
  };

  const zoomInEvent = () => {
    if (zoomLevel < 100) {
      let appContainer = document.getElementsByClassName("App");
      let kitContainer = document.getElementsByClassName("main-app-bg");

      appContainer[0].classList.remove(`zoom-${zoomLevel}`);
      kitContainer[0].classList.remove(`zoom-value-${zoomLevel}`);
      kitContainer[0].classList.remove(`zoom-${zoomLevel}`);

      setZoomLevel((prevValue) => prevValue + 20);
    }
  };

  const zoomOutEvent = () => {
    if (zoomLevel > 0) {
      let appContainer = document.getElementsByClassName("App");
      let kitContainer = document.getElementsByClassName("main-app-bg");

      appContainer[0].classList.remove(`zoom-${zoomLevel}`);
      kitContainer[0].classList.remove(`zoom-value-${zoomLevel}`);
      kitContainer[0].classList.remove(`zoom-${zoomLevel}`);

      setZoomLevel((prevValue) => prevValue - 20);
    }
  };

  const updateTeamName = async () => {
    const dataObj = {
      playerId: window.playerId,
      teamId: window.teamId,
      token: window.token,
      name: teamName,
    };
    await axios.post(urls.renameTeam, dataObj);

    setEditTeamName(false);

    // Update Socket
    RealtimeConnection.changeTeamName({
      value: dataObj.name,
    });
  };

  const closePanel = () => {
    props.closeProperties();
    setShowPopup(false);
  };

  console.log(counterData);

  return (
    <>
      <div className={`App-header ${props.boardType}`}>
        <div className="App-header-container">
          <div style={{ width: "15%", margin: "auto 0", height: "36px" }}>
            {showBackStepBtn ? (
              <div className="undo-btn" onClick={stepBack}>
                Undo Last Move
              </div>
            ) : null}
            {!canEdit ? (
              <div className="view-only">
                <Translate alias="Board" word="View Only" />
              </div>
            ) : null}
          </div>
          <div className="header-logo-container">
            <Image src={logo} className="App-logo" />
          </div>
          <div className="change-team-name-container">
            {canEdit && editTeamName ? (
              <Container className="bg-blue form">
                <div className="team-data-content">
                  <Row>
                    <Col className="team-form-container">
                      <input
                        type="text"
                        value={teamName}
                        onChange={(e) => setTeamName(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="team-form-container">
                      <div className="team-name-btn" onClick={updateTeamName}>
                        <Translate alias="Board" word="Save" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            ) : (
              <Container className="bg-blue">
                <div className="widget-content">
                  <div className="header-section">
                    <Translate alias="Board" word="Team" />
                  </div>
                  <div className="team-data-content">
                    <Row className="cursorPointer" onClick={updateEditTeamName}>
                      <Col>
                        <div className="team-name-text">{teamName}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            )}
          </div>
          <div className="player-name-container">
            <Container className="bg-green">
              <div className="widget-content">
                <div className="header-section">
                  <Translate alias="Board" word="Player" />
                </div>
                <div className="team-data-content">
                  <Row>
                    <Col>
                      <div className="player-name">
                        {window.playerName
                          ? `${window.playerName}`
                          : `Player ${window.playerId}`}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>
          <div className="year-container">
            <Container className="bg-blue">
              <div className="widget-content">
                <div className="team-data-content">
                  <Row>
                    <Col style={{ display: "flex" }}>
                      <div style={{ display: "flex", margin: "0 auto" }}>
                        <div className="header-section">
                          <Translate alias="Board" word="Year" />
                        </div>
                        <div className="year-value">{props.session.year}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>
          <div className="counter-container">
            {counterData && counterData.name ? (
              <Container className="bg-red">
                <div className="widget-content">
                  <div className="header-section">{counterData.name}</div>
                  <div className="team-data-content">
                    <Row>
                      <Col>
                        <div
                          className="counter-value"
                          style={{
                            color:
                              counterData.counterMinutes === 0 &&
                              counterData.counterSeconds > 0
                                ? "#FF0000"
                                : "#0A2955",
                          }}
                        >
                          {counterData.counterMinutes > 9
                            ? counterData.counterMinutes
                            : `0${counterData.counterMinutes}`}{" "}
                          :{" "}
                          {counterData.counterSeconds > 9
                            ? counterData.counterSeconds
                            : `0${counterData.counterSeconds}`}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="header-separator-2">&nbsp;</div>
              </Container>
            ) : null}
          </div>
          <div className="zoom-container">
            <Container>
              <Row>
                <Col>
                  <div className="zoom-btn-container" onClick={zoomInEvent}>
                    <Image src={zoomIn} className="zoom-btn" />
                  </div>
                </Col>
                <Col>
                  <div className="zoom-btn-container" onClick={zoomOutEvent}>
                    <Image src={zoomOut} className="zoom-btn" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="chat-icon-container">
            <div className="header-separator">&nbsp;</div>

            <div className="chat-container" onClick={toggleChat}>
              <Image src={chatIcon} />
              <div className="chat-title">
                <Translate alias="Board" word="Chat" />
              </div>
              {props.session.unreadCount > 0 && !props.session.isChatOpened ? (
                <div className="chat-count">{props.session.unreadCount}</div>
              ) : null}
            </div>
          </div>
          <div className="logout-btn-container">
            <div className="language-btn">
              <DropdownButton
                id="dropdown-item-button"
                title={selectedLanguageTitle}
              >
                {languages.map((lang) => (
                  <Dropdown.Item
                    key={lang.id}
                    as="button"
                    onClick={() => changeLanguage(lang.id)}
                  >
                    {lang.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div className="logout-btn" onClick={logout}>
              <Image src={logoutIcon} />
            </div>
          </div>
        </div>
      </div>
      {showPopup ? (
        <div className="field-properties-popup">
          <Popup open modal onClose={closePanel}>
            <div className="popup-body">
              {props.properties.isFieldSelected ? (
                <Properties
                  canEdit={canEdit}
                  startingKit={props.startingKit}
                  properties={props.properties}
                  board={props.board}
                  closePanel={closePanel}
                />
              ) : null}
            </div>
          </Popup>
        </div>
      ) : null}
      {openChat ? (
        <ChatComponent canEdit={canEdit} closeChat={toggleChat} />
      ) : null}
    </>
  );
};

/*class Header extends Component {

	constructor(props) {
		super(props);

		this.zoomInEvent.bind(this);
		this.zoomOutEvent.bind(this);

		this.counterTimer = {};

		this.state = {
			showBackStepBtn: (props.isAdmin) ? true : false,
			showPopup: false,
			openChat: false,
			canEdit: props.canEdit,
			zoomLevel: 0,
			editTeamName: false,
			teamName: (props.teamName) ? props.teamName : "Team-" + window.teamId,
			counterName: "",
			minutes: 0,
			languages: props.languages,
			counterMinutes: 0,
			counterSeconds: 0
		}
	}

	componentDidMount() {
		//this.fetchTeamData();
		this.fetchCounter();
	}

	async fetchTeamData() {
		const result = await axios.post(urls.teamDetails, {
			team_id: window.teamId
		})

		let response = result.data;
		console.log(response);
		if (response.status === 200) {
			this.setState({
				teamName: response.data.team.name
			});
		}
	}

	fetchCounter = async () => {
		const result = await axios.post(urls.activeCounter, {
			id: window.sessionId
		})

		let response = result.data;
		console.log(response);
		if (response.data) {
			console.log("HERE")
			this.counterTimer = response.data

			let startDate = moment.utc(this.counterTimer.createdAt);
			let endDate = startDate.clone().add(parseInt(this.counterTimer.duration), 'minutes');
			let currentDate = moment().utc()

			console.log(currentDate.diff(startDate));
			if (currentDate.diff(startDate) > 0 && endDate.diff(currentDate) > 0) {
				const diffMinutes = endDate.diff(currentDate);
				if (diffMinutes > 0) {
					this.myInterval = setInterval(() => {
						this.calculateCounter()
					}, 1000);
				}else{
					clearInterval(this.myInterval)
				}
			}else{
				this.setState({
					counterName: this.counterTimer.name,
					minutes: 0
				})
				clearInterval(this.myInterval)
			}
		}
	}

	updateTeamName() {
		const dataObj = {
			playerId: window.playerId,
			teamId: window.teamId,
			token: window.token,
			name: this.state.teamName
		};
		console.log(dataObj);
		this.changeTeamName(dataObj);

		// Update Socket
		RealtimeConnection.changeTeamName({
			value: dataObj.name
		})
	}

	async changeTeamName(data) {
		this.setState({
			editTeamName: false
		});

		const result = await axios.post(urls.renameTeam, data)
		console.log(result.data);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// To prevent multiple component updates
		if (this.props.properties.isFieldSelected && (this.props.properties.isFieldSelected !== prevProps.properties.isFieldSelected)) {
			this.openPanel()
		}
	}

	openPanel = () => {
		this.setState({ showPopup: true });
	}

	closePanel = () => {
		this.props.closeProperties();
		this.setState({ showPopup: false });
	}

	calculateCounter = () => {
		const currentDate = moment().utc();
		const counterDate = moment(this.counterTimer.createdAt).utc();
		const endDate = counterDate.clone().add(parseInt(this.counterTimer.duration), "minutes");

		const minutes = endDate.diff(currentDate)
		if (minutes > 0) {
			let minutesValue = 0
			let secondsValue = 0
			if (Math.ceil((minutes / 1000) % 60) === 60) {
				minutesValue = Math.ceil(minutes / 1000 / 60);
				secondsValue = 0;
			}else{
				if (minutes > 60000){
					minutesValue = Math.ceil(minutes / 1000 / 60) - 1;
				}else{
					minutesValue = 0
				}
				secondsValue = Math.ceil((minutes / 1000) % 60);
			}

			this.setState({
				counterName: this.counterTimer.name,
				counterMinutes: minutesValue,
				counterSeconds: secondsValue
			})
		}else{
			this.setState({
				counterName: this.counterTimer.name,
				minutes: `00 : 00`,
				counterMinutes: 0,
				counterSeconds: 0
			})
			clearInterval(this.myInterval);
		}
	}

	render() {
		if (this.props.session.timer.duration !== undefined && this.props.session.timer.createdAt !== this.counterTimer.createdAt) {
			this.counterTimer = { ...this.props.session.timer };

			if (this.props.session.timer.duration === 0) {
				this.setState({
					counterName: "",
					minutes: 0,
					counterMinutes: 0,
					counterSeconds: 0
				})
				clearInterval(this.myInterval)
			}else{
				let startDate = moment.utc(this.counterTimer.createdAt);
				let endDate = startDate.clone().add(parseInt(this.counterTimer.duration), 'minutes');
				let currentDate = moment()

				if (currentDate.diff(startDate) > 0 && endDate.diff(currentDate) > 0) {
					const diffMinutes = endDate.diff(currentDate);
					if (diffMinutes > 0) {
						this.myInterval = setInterval(() => {
							this.calculateCounter()
						}, 1000);
					}else{
						clearInterval(this.myInterval)
					}
				}else{
					this.setState({
						counterName: this.counterTimer.name,
						minutes: 0,
						counterMinutes: 0,
						counterSeconds: 0
					})
					clearInterval(this.myInterval)
				}
			}
		}
	}
}*/

const mapStateToProps = (state) => {
  return {
    board: state.board,
    properties: state.properties,
    startingKit: state.startingKit,
    session: state.session,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
