import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {Row} from "react-bootstrap";

// Constants
import dropFieldClassNames from "../../../../utils/dropFieldClassNames";

// Dispatchers
import mapDispatchToProps from "../../../../reducers/dispatchers";
import * as RealtimeConnection from "../../RealtimeConnection";

// Components
import NumberBox from "../../Common/NumberBox";
import DroppableField from "../../Common/DroppableField";


const ResearchRow = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [className, setClassName] = useState("")
	const [rowTitle, setRowTitle] = useState("")
	const [rowKey, setRowKey] = useState("")

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		setClassName(props.className)
	}, [props.className])

	useEffect(() => {
		setRowTitle(props.title)
	}, [props.title])

	useEffect(() => {
		setRowKey(props.rowKey.toLowerCase())
	}, [props.rowKey])

	const appendContainer = (coins) => {
		props.addContainerFieldType("Research", rowKey, coins, 0, false, 0, "", window.playerId, window.playerName)

		// Update Socket
		RealtimeConnection.addContainer("Research", {
			fieldType: rowKey,
			coins: coins
		})
	}

	const removeContainer = (isDropToDelete) => {
		props.removeContainerFieldType("Research", rowKey)

		// Update Socket
		RealtimeConnection.removeContainer("Research", {
			fieldType: rowKey,
			isDropToDelete: isDropToDelete
		})
	}

	const addCoin = (value) => {
		props.addCoinFieldType("Research", rowKey, value, 0, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addCoin("Research", {
			fieldType: rowKey,
			value: value
		})
	}

	const removeCoin = (index, isDropToDelete) => {
		props.removeCoinFieldType("Research", rowKey, index, 0, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.removeCoin("Research", {
			fieldType: rowKey,
			index: index,
			isDropToDelete: isDropToDelete
		})
	}

	const isDraggingHandler = (type, isDragging, data) => {
		console.log(data);
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Research",
			isDragging: isDragging,
			type: type,
			fieldType: rowKey
		}
		dataObj = { ...data, ...dataObj }

		window.socket.emit('item-dragging', dataObj);
	}

	const showContainerProperties = () => {
		props.openContainerPropertiesWithTitle('FIELD', 'Research', rowKey);
	}

	const renderNumber = (title) => {
		switch(title) {
			case "standard":
				return (
					<div className="number Number-box-container">
						<NumberBox number={0} />
					</div>
				)
			case "compact":
				return (
					<div className="number Number-box-container">
						<NumberBox number={3} />
						<NumberBox number={3} />
					</div>
				)
			case "plus":
				return (
					<div className="number Number-box-container">
						<NumberBox number={3} />
						<NumberBox number={3} />
						<NumberBox number={2} />
					</div>
				)
			case "luxus":
				return (
					<div className="number Number-box-container">
						<NumberBox number={2} />
						<NumberBox number={2} />
						<NumberBox number={2} />
						<NumberBox number={2} />
						<NumberBox number={2} />
					</div>
				)
			default:
				break;
		}
	}

	return (
		<Row className={`Research-row ${ className }`}>
			<div className="title Block-center">
				<div>{ rowTitle }</div>
			</div>
			{ renderNumber(rowKey) }
			<div className="droppable-field field" onClick={ showContainerProperties }>
				{
					(rowKey && 
						<DroppableField
							canEdit={canEdit}
							class={ dropFieldClassNames.Research }
							data={ props.research[rowKey] }
							isHorizontal={ true }
							appendContainer={ appendContainer }
							removeContainer={ removeContainer }
							addCoin={ addCoin }
							removeCoin={ removeCoin }
							isDraggingHandler={ isDraggingHandler }
						/>
					)
				}
			</div>
		</Row>
	)
}

const mapStateToProps = (state) => {
	return {
		research: state.board.CenterBlock.Research
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResearchRow);