import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Image } from 'react-bootstrap';

// Constants
import dropFieldClassNames from "../../../utils/dropFieldClassNames";

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";
import * as RealtimeConnection from "../RealtimeConnection";

// Images
import leftArrow from '../../../assets/images/left-arrow.svg';

// Components
import DroppableField from "../Common/DroppableField";


const LeftBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [blockId, setBlockId] = useState(0)
	const [blockTitle, setBlockTitle] = useState("")

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		setBlockId(props.id)
	}, [props.id])

	useEffect(() => {
		setBlockTitle(props.title)
	}, [props.title])


	const appendContainer = (coins) => {
		props.addContainer("LeftBlock", blockId, coins, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addContainer("LeftBlock", {
			id: blockId,
			coins: coins
		})
	}

	const removeContainer = (isDropToDelete) => {
		props.removeContainer("LeftBlock", blockId);

		// Update Socket
		RealtimeConnection.removeContainer("LeftBlock", {
			id: blockId,
			isDropToDelete: isDropToDelete
		})
	}

	const addCoin = (value) => {
		props.addCoin("LeftBlock", blockId, value, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addCoin("LeftBlock", {
			id: blockId,
			value: value
		})
	}

	const removeCoin = (index, isDropToDelete) => {
		props.removeCoin("LeftBlock", blockId, index, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.removeCoin("LeftBlock", {
			id: blockId,
			index: index,
			isDropToDelete: isDropToDelete
		})
	}

	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "LeftBlock",
			isDragging: isDragging,
			type: type,
			id: blockId
		}
		dataObj = { ...data, ...dataObj }

		window.socket.emit('item-dragging', dataObj);
	}

	const showContainerProperties = () => {
		props.openContainerProperties('FIELD', 'LeftBlock', blockId);
	}

	return (
		<Container className="left-right-block Left-block">
			<Row>
				<div className="field-column" onClick={showContainerProperties}>
					<DroppableField
						canEdit={canEdit}
						class={ dropFieldClassNames.LeftBlock }
						data={ props.leftBlock[blockId] }
						isHorizontal={ false }
						appendContainer={ appendContainer }
						removeContainer={ removeContainer }
						addCoin={ addCoin }
						removeCoin={ removeCoin }
						isDraggingHandler={ isDraggingHandler }
					/>
				</div>
				<div className="text-column">
					<p className="Text-bold-size">{ blockTitle }</p>
				</div>
			</Row>
			<Image src={ leftArrow } className="Arrow-class" />
		</Container>
	)
}

const mapStateToProps = (state) => {
	return {
		leftBlock: state.board.LeftBlock
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftBlock);