import React from 'react';
import { Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap';

import Translate from "../../../../utils/Translate"

class DecisionSheetFields extends React.Component {

	optionChanged(field, option, value) {
		this.props.inputChanged(this.props.section, field, option, value)
	}

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<div className="bold"><Translate alias="Forms" word="Standard" /></div>
						<div className="hintForm"><Translate alias="Forms" word="Price" /> 4-7</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Price" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.standard.price }
								onChange={ (e) => this.optionChanged("standard", "price", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Marketing" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.standard.marketing }
								onChange={ (e) => this.optionChanged("standard", "marketing", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Units" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.standard.units }
								onChange={ (e) => this.optionChanged("standard", "units", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="bold"><Translate alias="Forms" word="Compact" /></div>
						<div className="hintForm"><Translate alias="Forms" word="Price" /> 3-5</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Price" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.compact.price }
								onChange={ (e) => this.optionChanged("compact", "price", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Marketing" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.compact.marketing }
								onChange={ (e) => this.optionChanged("compact", "marketing", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Units" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.compact.units }
								onChange={ (e) => this.optionChanged("compact", "units", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="bold"><Translate alias="Forms" word="Plus" /></div>
						<div className="hintForm"><Translate alias="Forms" word="Price" /> 5-8</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Price" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.plus.price }
								onChange={ (e) => this.optionChanged("plus", "price", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Marketing" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.plus.marketing }
								onChange={ (e) => this.optionChanged("plus", "marketing", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Units" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.plus.units }
								onChange={ (e) => this.optionChanged("plus", "units", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="bold"><Translate alias="Forms" word="Luxus" /></div>
						<div className="hintForm"><Translate alias="Forms" word="Price" /> 7-11</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Price" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.luxus.price }
								onChange={ (e) => this.optionChanged("luxus", "price", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Marketing" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.luxus.marketing }
								onChange={ (e) => this.optionChanged("luxus", "marketing", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Units" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.luxus.units }
								onChange={ (e) => this.optionChanged("luxus", "units", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
					</Col>
				</Row>
			</Container>
		);
	}

}

export default DecisionSheetFields;