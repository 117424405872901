import React, { useEffect, useState} from "react";
import { connect } from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

import Translate from '../../../../utils/Translate'

// Constants
import dropFieldClassNames from "../../../../utils/dropFieldClassNames";

// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";
import * as RealtimeConnection from "../../RealtimeConnection";

// Components
import DroppableField from '../../Common/DroppableField';
import TokenDroppableContainer from "./TokenDroppableContainer";
import Tooltip from "rc-tooltip/es";

const FinanceBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])


	const appendContainer = (coins, id) => {
		props.addContainer("Finance", id, coins, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addContainer("Finance", {
			id: id,
			coins: coins
		})
	}

	const removeContainer = (id, isDropToDelete) => {
		props.removeContainer("Finance", id);

		// Update Socket
		RealtimeConnection.removeContainer("Finance", {
			id: id,
			isDropToDelete: isDropToDelete
		})
	}

	const addCoin = (id, value) => {
		props.addCoin("Finance", id, value, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addCoin("Finance", {
			id: id,
			value: value
		})
	}

	const removeCoin = (id, index, isDropToDelete) => {
		props.removeCoin("Finance", id, index, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.removeCoin("Finance", {
			id: id,
			index: index,
			isDropToDelete: isDropToDelete
		})
	}

	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Finance",
			isDragging: isDragging,
			type: type
		}
		dataObj = { ...dataObj, ...data }

		window.socket.emit('item-dragging', dataObj);
	}

	const onCapitalFocus = () => {
		console.log(" On Focus ");
		const dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Finance",
			isDragging: true,
			type: "CAPITAL"
		}
		window.socket.emit('capital-editing', dataObj);
	}

	const onCapitalEnd = () => {
		console.log(" On End ");
		const dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Finance",
			isDragging: false,
			type: "CAPITAL"
		}
		window.socket.emit('capital-editing', dataObj);
	}

	const onCapitalChange = (event) => {
		props.updateCapital(event.target.value);

		const dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			value: event.target.value
		}
		window.socket.emit('update-capital', dataObj);
	}

	const showTokenProperties = (tokenType) => {
		if (tokenType === "token_black") {
			if (props.startingKit.blackTokens === 0 && props.finance.token_black.value === 0){
				return;
			}
		}
		props.openTokenProperties('TOKEN', tokenType);
	}

	const showContainerProperties = (id) => {
		props.openContainerProperties('FIELD', "Finance", id);
	}


	let showTooltip = false
	let canEditCapital = !canEdit

	if (props.finance.isDragging && props.finance.type === "CAPITAL") {
		if (props.finance.playerId !== window.playerId) {
			showTooltip = true
			canEditCapital = false
		}
	}


	const cashList = props.finance.cash;
	const droppableFieldsList = cashList.map((value, index) => {
		return (
			<React.Fragment key={index}>
				{
					(index % 4 === 0) ?
						<div className="w-100"></div>
						:
						null
				}
				<Col onClick={ () => showContainerProperties(index) }>
					<DroppableField
						canEdit={canEdit}
						id={ index }
						class={ dropFieldClassNames.Finance }
						data={ value }
						isHorizontal={ true }
						appendContainer={appendContainer}
						removeContainer={removeContainer}
						addCoin={addCoin}
						removeCoin={removeCoin}
						isDraggingHandler={ isDraggingHandler }
					/>
				</Col>
			</React.Fragment>
		);
	})

	return (
		<div className="Finance-container">
			<Row>
				<Col>
					<div className="Finance-bold-title-text-size"><Translate alias="Board" word="Finance" /></div>
				</Col>
			</Row>
			<Row>
				<div className="Finance-capital-container">
					<Row>
						<Col>
							<div className="Finance-bold-text Finance-text-size">
								<Translate alias="Board" word="Equity" />
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="Finance-capital-text">
								<Translate alias="Board" word="Capital" />
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="Finance-capital-field">
								<Tooltip placement="topRight" visible={ showTooltip }  overlay={<span>{ props.finance.playerName }</span>}>
									<input
										id="capitalInput"
										value={ props.finance.capital }
										readOnly={ canEditCapital }
										onChange={ onCapitalChange }
										onFocus={ onCapitalFocus }
										onBlur={ onCapitalEnd }
										className="capitalInput"
										type="text"
									/>
								</Tooltip>
							</div>
						</Col>
					</Row>
				</div>
				<div className="Finance-cash-container">
					<span className="Finance-cash-text-size">
						<span className="Text-bold">
							<Translate alias="Board" word="Cash" />
						</span>
					</span>
					<div className="Finance-droppable-container">
						<Row>{ droppableFieldsList }</Row>
					</div>
				</div>
			</Row>
			<Row className="Liability-block">
				<div className="width-20-percent">
					<Container>
						<Row>
							<Col>
								<div className="Finance-capital-text">
									<Translate alias="Board" word="liability current 10%" />
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="width-20-percent" onClick={ () => showTokenProperties("token_10")}>
					<TokenDroppableContainer
						canEdit={canEdit}
						type="token_10"
						value={props.finance.token_10}
					/>
				</div>
				<div className="width-20-percent" onClick={ () => showTokenProperties("token_black")}>
					{
						(props.startingKit.blackTokens > 0 || props.finance.token_black.value > 0) ?
							<TokenDroppableContainer
								canEdit={canEdit}
								type="token_black"
								value={props.finance.token_black}
							/>
							:
							null
					}
				</div>
				<div className="width-20-percent">
					<Container className="Liability-right-section">
						<Row>
							<Col>
								<div className="Finance-capital-text">
									<Translate alias="Board" word="liabilities non-current 5%" />
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="width-20-percent" onClick={ () => showTokenProperties("token_5")}>
					<TokenDroppableContainer
						canEdit={canEdit}
						type="token_5"
						value={props.finance.token_5}
					/>
				</div>
			</Row>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		finance: state.board.CenterBlock.Finance,
		startingKit: state.startingKit
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FinanceBlock);