import React, { useEffect, useState } from "react";

// Components
import SalesDroppablePin from "./SalesDroppablePin";

const SalesNumberBox = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [number, setNumber] = useState(0)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		setNumber(props.number)
	}, [props.number])

	return (
		<SalesDroppablePin
			canEdit={canEdit}
			id={props.id}
			number={number}
			hasPin={props.hasPin}
			title={props.title}
			appendPin={props.appendPin}
			removePin={props.removePin}
		/>
	)
}

export default SalesNumberBox;