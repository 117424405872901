const dispatchToProps = (dispatch) => {
  return {
    updateState: (data) => dispatch({ type: "UPDATE_STATE", data: data }),
    updateCapital: (value) =>
      dispatch({ type: "UPDATE_CAPITAL", value: value }),
    addContainer: (alias, id, coins, playerId, playerName) =>
      dispatch({
        type: "ADD_CONTAINER",
        alias: alias,
        id: id,
        coins: coins,
        playerId: playerId,
        playerName: playerName,
      }),
    addContainerFieldType: (
      alias,
      fieldType,
      coins,
      id,
      hasCard,
      cardValue,
      cardColor,
      playerId,
      playerName
    ) =>
      dispatch({
        type: "ADD_CONTAINER",
        alias: alias,
        fieldType: fieldType,
        coins: coins,
        id: id,
        hasCard: hasCard,
        cardValue: cardValue,
        cardColor: cardColor,
        playerId: playerId,
        playerName: playerName,
      }),
    addContainerColorField: (
      alias,
      color,
      id,
      coins,
      hasCard,
      cardValue,
      cardColor,
      playerId,
      playerName
    ) =>
      dispatch({
        type: "ADD_CONTAINER",
        alias: alias,
        color: color,
        id: id,
        coins: coins,
        hasCard: hasCard,
        cardValue: cardValue,
        cardColor: cardColor,
        playerId: playerId,
        playerName: playerName,
      }),
    addContainerDirectCost: (
      alias,
      id,
      coins,
      hasCard,
      cardValue,
      cardColor,
      playerId,
      playerName
    ) =>
      dispatch({
        type: "ADD_CONTAINER",
        alias: alias,
        id: id,
        coins: coins,
        hasCard: hasCard,
        cardValue: cardValue,
        cardColor: cardColor,
        playerId: playerId,
        playerName: playerName,
      }),
    removeContainer: (alias, id) =>
      dispatch({ type: "REMOVE_CONTAINER", alias: alias, id: id }),
    removeContainerFieldType: (alias, fieldType, id) =>
      dispatch({
        type: "REMOVE_CONTAINER",
        alias: alias,
        fieldType: fieldType,
        id: id,
      }),
    removeContainerColorField: (alias, color, id) =>
      dispatch({
        type: "REMOVE_CONTAINER",
        alias: alias,
        color: color,
        id: id,
      }),
    addCoin: (alias, id, coinValue, playerId, playerName) =>
      dispatch({
        type: "ADD_COIN",
        alias: alias,
        id: id,
        coinValue: coinValue,
        playerId: playerId,
        playerName: playerName,
      }),
    addCoinFieldType: (alias, fieldType, coinValue, id, playerId, playerName) =>
      dispatch({
        type: "ADD_COIN",
        alias: alias,
        fieldType: fieldType,
        coinValue: coinValue,
        id: id,
        playerId: playerId,
        playerName: playerName,
      }),
    removeCoin: (alias, id, index, playerId, playerName) =>
      dispatch({
        type: "REMOVE_COIN",
        alias: alias,
        id: id,
        coinIndex: index,
        playerId: playerId,
        playerName: playerName,
      }),
    removeCoinFieldType: (alias, fieldType, index, id, playerId, playerName) =>
      dispatch({
        type: "REMOVE_COIN",
        alias: alias,
        fieldType: fieldType,
        coinIndex: index,
        id: id,
        playerId: playerId,
        playerName: playerName,
      }),
    addCoins: (alias, id, coins, playerId, playerName) =>
      dispatch({
        type: "ADD_COINS",
        alias: alias,
        id: id,
        coins: coins,
        playerId: playerId,
        playerName: playerName,
      }),
    addCoinsFieldType: (alias, fieldType, coins, id, playerId, playerName) =>
      dispatch({
        type: "ADD_COINS",
        alias: alias,
        fieldType: fieldType,
        id: id,
        coins: coins,
        playerId: playerId,
        playerName: playerName,
      }),
    updateCoins: (alias, id, coins, playerId, playerName) =>
      dispatch({
        type: "UPDATE_COINS",
        alias: alias,
        id: id,
        coins: coins,
        playerId: playerId,
        playerName: playerName,
      }),
    updateCoinsFieldType: (alias, fieldType, coins, id, playerId, playerName) =>
      dispatch({
        type: "UPDATE_COINS",
        alias: alias,
        fieldType: fieldType,
        id: id,
        coins: coins,
        playerId: playerId,
        playerName: playerName,
      }),
    openContainerProperties: (alias, fieldName, id) =>
      dispatch({
        type: "PROPERTIES",
        alias: alias,
        options: { fieldName: fieldName, id: id },
      }),
    openContainerPropertiesWithTitle: (alias, fieldName, title, id) =>
      dispatch({
        type: "PROPERTIES",
        alias: alias,
        options: { fieldName: fieldName, title: title, id: id },
      }),
    openContainerPropertiesWithOption: (alias, fieldName, optionType, id) =>
      dispatch({
        type: "PROPERTIES",
        alias: alias,
        options: { fieldName: fieldName, optionType: optionType, id: id },
      }),
    openTokenProperties: (alias, type) =>
      dispatch({
        type: "PROPERTIES",
        alias: alias,
        options: { tokenType: type },
      }),
    closeProperties: () => dispatch({ type: "CLOSE_PROPERTIES" }),
    addPin: (alias, title, speed, id) =>
      dispatch({
        type: "ADD_PIN",
        alias: alias,
        title: title,
        speed: speed,
        id: id,
      }),
    removePin: (alias, title, speed, id) =>
      dispatch({
        type: "REMOVE_PIN",
        alias: alias,
        title: title,
        speed: speed,
        id: id,
      }),
    addCard: (alias, optionType, id, value, color) =>
      dispatch({
        type: "ADD_CARD",
        alias: alias,
        optionType: optionType,
        id: id,
        cardValue: value,
        cardColor: color,
      }),
    addToken: (tokenType, tokenValue, droppedTokenType) =>
      dispatch({
        type: "ADD_TOKEN",
        tokenType: tokenType,
        value: tokenValue,
        droppedTokenType: droppedTokenType,
      }),
    removeToken: (tokenType, tokenValue) =>
      dispatch({
        type: "REMOVE_TOKEN",
        tokenType: tokenType,
        value: tokenValue,
      }),
    removeStartKit: (id) => dispatch({ type: "REMOVE_START_KIT", id: id }),
    increaseStartKitPin: (count) =>
      dispatch({ type: "INCREASE_PIN", count: count }),
    decreaseStartKitPin: (count) =>
      dispatch({ type: "DECREASE_PIN", count: count }),
    increaseStartKitCoin: (value) =>
      dispatch({ type: "INCREASE_COIN", value: value }),
    decreaseStartKitCoin: (value) =>
      dispatch({ type: "DECREASE_COIN", value: value }),
    decreaseStartKitToken: (value) =>
      dispatch({ type: "DECREASE_TOKEN", value: value }),
    increaseStartKitToken: (value) =>
      dispatch({ type: "INCREASE_TOKEN", value: value }),
    decreaseStartKitBlackToken: (value) =>
      dispatch({ type: "DECREASE_BLACK_TOKEN", value: value }),
    increaseStartKitBlackToken: (value) =>
      dispatch({ type: "INCREASE_BLACK_TOKEN", value: value }),
    increaseStartKitFactory: (count) =>
      dispatch({ type: "INCREASE_FACTORY", value: count }),
    decreaseStartKitFactory: (value) =>
      dispatch({ type: "DECREASE_FACTORY", value: value }),
    increaseStartKitMachine: (count) =>
      dispatch({ type: "INCREASE_MACHINE", value: count }),
    decreaseStartKitMachine: (value) =>
      dispatch({ type: "DECREASE_MACHINE", value: value }),
    updateStartKitProduction: (value) =>
      dispatch({ type: "UPDATE_PRODUCTION", value: value }),
    increaseStartKitProductCard: (value, color) =>
      dispatch({ type: "INCREASE_PRODUCTION", value: value, color: color }),
    decreaseStartKitProductCard: (value, color) =>
      dispatch({ type: "DECREASE_PRODUCTION", value: value, color: color }),
    updateYear: (value) => dispatch({ type: "UPDATE_YEAR", value: value }),
    updateTeamName: (value) =>
      dispatch({ type: "UPDATE_TEAM_NAME", value: value }),
    updateCounter: (value) =>
      dispatch({ type: "UPDATE_COUNTER", value: value }),
    updateFiles: (value) => dispatch({ type: "UPDATE_FILES", value: value }),
    addFile: (value) => dispatch({ type: "ADD_FILE", value: value }),
    removeFile: (id) => dispatch({ type: "REMOVE_FILE", id: id }),

    addMessage: (value) => dispatch({ type: "ADD_MESSAGE", value: value }),
    addMessagesList: (value) =>
      dispatch({ type: "MESSAGES_LIST", value: value }),
    toggleChat: (isOpened) =>
      dispatch({ type: "TOGGLE_CHAT", isOpened: isOpened }),

    rentFactory: (id) => dispatch({ type: "FACTORY_RENT", id: id }),
    purchaseFactory: (id) => dispatch({ type: "FACTORY_PURCHASE", id: id }),

    updateDragStatus: (alias, type, isDragging, data) =>
      dispatch({
        type: "UPDATE_DRAGGING",
        alias: alias,
        itemType: type,
        data: data,
        isDragging: isDragging,
      }),
    updateCapitalStatus: (type, isDragging, data) =>
      dispatch({
        type: "UPDATE_CAPITAL_STATUS",
        itemType: type,
        data: data,
        isDragging: isDragging,
      }),

    updateForm: (type, value) =>
      dispatch({ type: "UPDATE_FORM", form: type, value: value }),
    updateFormOption: (type, section, field, option, value) =>
      dispatch({
        type: type,
        section: section,
        field: field,
        option: option,
        value: value,
      }),
    openFormView: (value) => dispatch({ type: "OPEN_FORM", value: value }),
    restoreFormStatus: (data) =>
      dispatch({ type: "RESTORE_FORM_STATUS", data: data }),
    updateFormStatus: (data) =>
      dispatch({ type: "UPDATE_FORM_STATUS", data: data }),
    changeLanguage: (value) =>
      dispatch({ type: "CHANGE_LANGUAGE", value: value }),
    changeFormYear: (year) =>
      dispatch({ type: "CHANGE_FORM_YEAR", year: year }),

    updateTeamTable: (data) => dispatch({ type: "UPDATE_TEAM_TABLE", data }),
    decreaseStarterKit: (data) =>
      dispatch({ type: "DECREASE_STARTER_KIT", data }),
    increaseStarterKit: (data) =>
      dispatch({ type: "INCREASE_STARTER_KIT", data }),
  };
};

export default dispatchToProps;
