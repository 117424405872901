import React from 'react';
import { Table, FormControl } from 'react-bootstrap';

import Translate from '../../../../utils/Translate'

class CashFlow extends React.Component {

	handleChange = (field, value) => {
		this.props.inputChanged("cashFlow", field, value)
	}

	render() {

		let totalIncomes = 0
		totalIncomes += (!isNaN(parseFloat(this.props.cashFlow.salesRevenue))) ? parseFloat(this.props.cashFlow.salesRevenue) : 0;
		totalIncomes += (!isNaN(parseFloat(this.props.cashFlow.otherIncomes))) ? parseFloat(this.props.cashFlow.otherIncomes) : 0;

		let totalOverhead = 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.materialPurchase))) ? parseFloat(this.props.cashFlow.materialPurchase) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.labour))) ? parseFloat(this.props.cashFlow.labour) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.indirectRAndDCosts))) ? parseFloat(this.props.cashFlow.indirectRAndDCosts) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.productDevelopmentCost))) ? parseFloat(this.props.cashFlow.productDevelopmentCost) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.productionFacilities))) ? parseFloat(this.props.cashFlow.productionFacilities) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.machines))) ? parseFloat(this.props.cashFlow.machines) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.lease))) ? parseFloat(this.props.cashFlow.lease) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.indirectProductionCosts))) ? parseFloat(this.props.cashFlow.indirectProductionCosts) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.marketing))) ? parseFloat(this.props.cashFlow.marketing) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.storageCosts))) ? parseFloat(this.props.cashFlow.storageCosts) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.qualityAssurance))) ? parseFloat(this.props.cashFlow.qualityAssurance) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.indirectSalesCosts))) ? parseFloat(this.props.cashFlow.indirectSalesCosts) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.processOptimization))) ? parseFloat(this.props.cashFlow.processOptimization) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.otherOverheads))) ? parseFloat(this.props.cashFlow.otherOverheads) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.interest))) ? parseFloat(this.props.cashFlow.interest) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.cashFlow.taxes))) ? parseFloat(this.props.cashFlow.taxes) : 0

		const netCashFlow = totalIncomes - totalOverhead;


		let cashLevelAtEnd = (!isNaN(parseFloat(this.props.cashFlow.cashLevelAtStart))) ? parseFloat(this.props.cashFlow.cashLevelAtStart) : 0;
		cashLevelAtEnd += netCashFlow;
		cashLevelAtEnd += (!isNaN(parseFloat(this.props.cashFlow.repayments))) ? parseFloat(this.props.cashFlow.repayments) : 0;
		

		return (
			<Table bordered>
				<tbody>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Cash level at start of year" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.cashLevelAtStart }
								onChange={ (e) => this.handleChange("cashLevelAtStart", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Sales revenue" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.salesRevenue }
								onChange={ (e) => this.handleChange("salesRevenue", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Other incomes" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.otherIncomes }
								onChange={ (e) => this.handleChange("otherIncomes", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Total Incomes" /></div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ totalIncomes } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Materials purchase" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.materialPurchase }
								onChange={ (e) => this.handleChange("materialPurchase", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Labour" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.labour }
								onChange={ (e) => this.handleChange("labour", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Indirect R&D costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.indirectRAndDCosts }
								onChange={ (e) => this.handleChange("indirectRAndDCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Product development cost" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.productDevelopmentCost }
								onChange={ (e) => this.handleChange("productDevelopmentCost", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Purchase of production facilities" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.productionFacilities }
								onChange={ (e) => this.handleChange("productionFacilities", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Purchase of machines" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.machines }
								onChange={ (e) => this.handleChange("machines", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Lease" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.lease }
								onChange={ (e) => this.handleChange("lease", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Indirect production costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.indirectProductionCosts }
								onChange={ (e) => this.handleChange("indirectProductionCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Marketing" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.marketing }
								onChange={ (e) => this.handleChange("marketing", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Storage costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.storageCosts }
								onChange={ (e) => this.handleChange("storageCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Quality assurance" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.qualityAssurance }
								onChange={ (e) => this.handleChange("qualityAssurance", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Indirect sales costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.indirectSalesCosts }
								onChange={ (e) => this.handleChange("indirectSalesCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Process optimization" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.processOptimization }
								onChange={ (e) => this.handleChange("processOptimization", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Other overheads" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.otherOverheads }
								onChange={ (e) => this.handleChange("otherOverheads", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Interest" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.interest }
								onChange={ (e) => this.handleChange("interest", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Taxes" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.taxes }
								onChange={ (e) => this.handleChange("taxes", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Total expenditures" /></div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ totalOverhead } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Net Cash Flow" /></div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ netCashFlow } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Credit take-ups/repayments" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.repayments }
								onChange={ (e) => this.handleChange("repayments", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Cash level at year end" /></div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ cashLevelAtEnd } disabled />
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}

}

export default CashFlow;