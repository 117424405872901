import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

import Translate from "../../../../utils/Translate"

// Constants
import dropFieldClassNames from "../../../../utils/dropFieldClassNames";
import items from "../../../../utils/items";

// Dispatchers
import mapDispatchToProps from "../../../../reducers/dispatchers";
import * as RealtimeConnection from "../../RealtimeConnection";

// Components
import NumberBox from "../../Common/NumberBox";
import ProductionDroppablePin from "./ProductionDroppablePin";
import DroppableField from "../../Common/DroppableField";


const ProductionBlockSection = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [rowId, setRowId] = useState(0)
	const [rowTitle, setRowTitle] = useState("")

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		setRowId(props.id)
	}, [props.id])

	useEffect(() => {
		setRowTitle(props.title)
	}, [props.title])

	const appendPin = (title, speed, id) => {
		props.addPin("Production", title, speed, id)

		// Update Socket
		RealtimeConnection.addPin("Production", {
			title: title,
			speed: speed,
			id: id
		})
	}

	const removePin = (title, speed, id) => {
		props.removePin("Production", title, speed, id)

		// Update Socket
		RealtimeConnection.removePin("Production", {
			title: title,
			speed: speed,
			id: id
		})
	}

	const appendContainer = (fieldType, coins, id, hasCard, cardValue, cardColor) => {
		props.addContainerFieldType("Production", fieldType, coins, id, hasCard, cardValue, cardColor, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addContainer("Production", {
			fieldType: fieldType,
			id: id,
			coins: coins,
			hasCard: hasCard,
			cardValue: cardValue,
			cardColor: cardColor
		})
	}

	const removeContainer = (fieldType, id, isDropToDelete) => {
		props.removeContainerFieldType("Production", fieldType, id);

		// Update Socket
		RealtimeConnection.removeContainer("Production", {
			fieldType: fieldType,
			id: id,
			isDropToDelete: isDropToDelete
		})
	}

	const addCoin = (fieldType, coinValue, id) => {
		props.addCoinFieldType("Production", fieldType, coinValue, id, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addCoin("Production", {
			fieldType: fieldType,
			id: id,
			value: coinValue
		})
	}

	const removeCoin = (fieldType, index, id, isDropToDelete) => {
		props.removeCoinFieldType("Production", fieldType, index, id, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.removeCoin("Production", {
			fieldType: fieldType,
			id: id,
			index: index,
			isDropToDelete: isDropToDelete
		})
	}

	const addCard = (optionType, id, value, color) => {
		props.addCard("Production", optionType, id, value, color);

		// Update Socket
		RealtimeConnection.addCard("Production", {
			optionType: optionType,
			id: id,
			value: value,
			color: color
		})
	}

	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Production",
			isDragging: isDragging,
			type: type
		}
		dataObj = { ...dataObj, ...data }
		window.socket.emit('item-dragging', dataObj);
	}

	const showContainerProperties = (optionType) => {
		props.openContainerPropertiesWithOption('FIELD', 'Production', optionType, rowId);
	}

	const renderNumbers = (index) => {
		switch(rowTitle.toLowerCase()) {
			case "national":
			case "international":
				if (index === 0 || index === 1 || index === 2){
					return (
						<NumberBox isProduction={true} number={2}></NumberBox>
					);
				}else{
					return (
						<NumberBox isProduction={true} number={3}></NumberBox>
					);
				}
			case "inter-continental 1":
				if (index === 0 || index === 1 || index === 2){
					return (
						<NumberBox isProduction={true} number={1.5}></NumberBox>
					);
				}else{
					return (
						<NumberBox isProduction={true} number={2.5}></NumberBox>
					);
				}
			case "inter-continental 2":
				if (index === 0 || index === 1 || index === 2){
					return (
						<NumberBox isProduction={true} number={1}></NumberBox>
					);
				}else{
					return (
						<NumberBox isProduction={true} number={2}></NumberBox>
					);
				}
			default:
				break;
		}
	}

	return (
		<Container className="Production-block-container">
			<Row className="custom-height">
				<Col xs={4}>
					<div className="Block-title-bold-text Production-title-text-size Same-line">
						<Translate alias="Board" word="Region" />
					</div>
				</Col>
				<Col>
					<div className="Block-title-bold-text Production-title-text-size Same-line">
						<Translate alias="Board" word="labor costs/unit" />
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={4}>
					<div style={{ display: "table", width: "90%" }}>
						<div className="production-block-title-box">
							<div className="production-block-title-box-text">
								{ (rowId + 1) } <Translate alias="Forms" word={ rowTitle } />
							</div>
						</div>
					</div>
				</Col>
				<Col>
					<Container>
						<Row>
							<Col className="">
								<div className="standard height">
									<div className="full-width-height margin-V-Center">
										{ renderNumbers(0) }
									</div>
								</div>
							</Col>
							<Col className="">
								<div className="compact height">
									<div className="full-width-height margin-V-Center">
										{ renderNumbers(1) }
									</div>
								</div>
							</Col>
							<Col className="">
								<div className="plus height">
									<div className="full-width-height margin-V-Center">
										{ renderNumbers(2) }
									</div>
								</div>
							</Col>
							<Col className="">
								<div className="luxus height">
									<div className="full-width-height margin-V-Center">
										{ renderNumbers(3) }
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>
			<Row className="custom-height">
				<Col xs={4}>
					<div className="Block-title-bold-text Production-sub-title-text-size">
						<Translate alias="Board" word="Machine" />
					</div>
				</Col>
				<Col>
					<Container>
						<Row>
							<Col>
								<div className="standard custom-height"> &nbsp; </div>
							</Col>
							<Col>
								<div className="compact custom-height"> &nbsp; </div>
							</Col>
							<Col>
								<div className="plus custom-height"> &nbsp; </div>
							</Col>
							<Col>
								<div className="luxus custom-height"> &nbsp; </div>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>
			<Row className="custom-row-line">
				<Col xs={4} className="zIndex-3">
					<div className="full-width-height margin-V-Center">
						<Row className="speed-row-text margin-V-Center">
							<Col xs={3} className="marginAuto">
								<div className="speed-text center">
									<span className="bold-text capitalizeLetter">
										<Translate alias="Forms" word="f" />
									</span>
								</div>
							</Col>
							<Col className="margin-V-Center">
								<div className="speed-text lowwerCaseLetter">
									<Translate alias="Forms" word="Fast" />
								</div>
							</Col>
						</Row>
					</div>
				</Col>
				<Col>
					<Container>
						<Row>
							<Col>
								<div className="standard custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="standard"
										speed="fast"
										hasPin={props.production[rowId].pins.fast.standard}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
							<Col>
								<div className="compact custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="compact"
										speed="fast"
										hasPin={props.production[rowId].pins.fast.compact}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
							<Col>
								<div className="plus custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="plus"
										speed="fast"
										hasPin={props.production[rowId].pins.fast.plus}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
							<Col>
								<div className="luxus custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="luxus"
										speed="fast"
										hasPin={props.production[rowId].pins.fast.luxus}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>
			<Row className="custom-row-line">
				<Col xs={4} className="zIndex-3">
					<div className="full-width-height margin-V-Center">
						<Row className="speed-row-text margin-V-Center">
							<Col xs={3} className="marginAuto">
								<div className="speed-text center">
									<span className="bold-text capitalizeLetter">
										<Translate alias="Forms" word="m" />
									</span>
								</div>
							</Col>
							<Col className="margin-V-Center">
								<div className="speed-text lowwerCaseLetter">
									<Translate alias="Forms" word="Medium" />
								</div>
							</Col>
						</Row>
					</div>
				</Col>
				<Col>
					<Container>
						<Row>
							<Col>
								<div className="standard custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="standard"
										speed="medium"
										hasPin={props.production[rowId].pins.medium.standard}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
							<Col>
								<div className="compact custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="compact"
										speed="medium"
										hasPin={props.production[rowId].pins.medium.compact}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
							<Col>
								<div className="plus custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="plus"
										speed="medium"
										hasPin={props.production[rowId].pins.medium.plus}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
							<Col>
								<div className="luxus custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="luxus"
										speed="medium"
										hasPin={props.production[rowId].pins.medium.luxus}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>
			<Row className="custom-row-line">
				<Col xs={4} className="zIndex-3">
					<div className="full-width-height margin-V-Center">
						<Row className="speed-row-text margin-V-Center">
							<Col xs={3} className="marginAuto">
								<div className="speed-text center">
									<span className="bold-text capitalizeLetter">
										<Translate alias="Forms" word="s" />
									</span>
								</div>
							</Col>
							<Col className="margin-V-Center">
								<div className="speed-text lowwerCaseLetter">
									<Translate alias="Forms" word="Slow" />
								</div>
							</Col>
						</Row>
					</div>
				</Col>
				<Col>
					<Container>
						<Row>
							<Col>
								<div className="standard custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="standard"
										speed="slow"
										hasPin={props.production[rowId].pins.slow.standard}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
							<Col>
								<div className="compact custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="compact"
										speed="slow"
										hasPin={props.production[rowId].pins.slow.compact}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
							<Col>
								<div className="plus custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="plus"
										speed="slow"
										hasPin={props.production[rowId].pins.slow.plus}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
							<Col>
								<div className="luxus custom-height-17">
									<ProductionDroppablePin
										canEdit={canEdit}
										id={rowId}
										title="luxus"
										speed="slow"
										hasPin={props.production[rowId].pins.slow.luxus}
										appendPin={appendPin}
										removePin={removePin}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>
			<Row style={{"marginTop": "5px"}}>
				<div style={{ "width": "37%" }}>
					<Container>
						<Row>
							<div className="vertical-text-container">
								<div className="vertical-text first text-bold">
									<Translate alias="Board" word="purchased production plant" />
								</div>
							</div>
							<div className="production-field-container" onClick={ () => showContainerProperties("factory")}>
								<DroppableField
									canEdit={canEdit}
									class={ dropFieldClassNames.Production }
									id={ rowId }
									data={ props.production[rowId].factory }
									containerType={items.factoryContainer}
									productionType={ "factory" }
									isHorizontal={ false }
									appendContainer={ appendContainer }
									removeContainer={ removeContainer }
									addCoin={ addCoin }
									removeCoin={ removeCoin }
									addCard={ addCard }
									isDraggingHandler={ isDraggingHandler }
								/>
							</div>
						</Row>
					</Container>
				</div>
				<div style={{ "width": "31.5%" }}>
					<Container>
						<Row>
							<div className="vertical-text-container2">
								<div className="vertical-text second text-bold">
									<Translate alias="Board" word="Machine" />
								</div>
							</div>
							<div className="production-field-container2" onClick={ () => showContainerProperties("machine")}>
								<DroppableField
									canEdit={canEdit}
									class={ dropFieldClassNames.Production }
									id={ rowId }
									data={ props.production[rowId].machine }
									containerType={items.machineContainer}
									productionType={ "machine" }
									isHorizontal={ false }
									appendContainer={ appendContainer }
									removeContainer={ removeContainer }
									addCoin={ addCoin }
									removeCoin={ removeCoin }
									addCard={ addCard }
									isDraggingHandler={ isDraggingHandler }
								/>
							</div>
						</Row>
					</Container>
				</div>
				<div style={{ "width": "31.5%" }}>
					<Container>
						<Row>
							<div className="vertical-text-container2">
								<div className="vertical-text second text-bold">
									<Translate alias="Board" word="Products" />
								</div>
							</div>
							<div className="production-field-container2" onClick={ () => showContainerProperties("product")}>
								<DroppableField
									canEdit={canEdit}
									class={ dropFieldClassNames.Production }
									id={ rowId }
									data={ props.production[rowId].product }
									containerType={items.productionContainer}
									productionType={ "product" }
									isHorizontal={ false }
									appendContainer={ appendContainer }
									removeContainer={ removeContainer }
									addCoin={ addCoin }
									removeCoin={ removeCoin }
									addCard={ addCard }
									isDraggingHandler={ isDraggingHandler }
								/>
							</div>
						</Row>
					</Container>
				</div>
			</Row>
		</Container>
	)

}

const mapStateToProps = (state) => {
	return {
		production: state.board.CenterBlock.Production
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductionBlockSection);