import React, { useEffect, useState} from "react";
import {Row, Col} from "react-bootstrap";

import Translate from '../../../../utils/Translate'

// Components
import ResearchRow from "./ResearchRow";

const ResearchBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	return (
		<div className="Research-block-container">
			<Row>
				<Col>
					<p className="Research-bold-text Research-title-text-size"><Translate alias="Board" word="R & D" /></p>
					<div className="Research-subtitle-text-size"><Translate alias="Board" word="capitalized development costs" /></div>
				</Col>
			</Row>
			<ResearchRow canEdit={canEdit} className="Standard-row" rowKey="standard" title={ <Translate alias="Board" word="Standard" /> } />
			<ResearchRow canEdit={canEdit} className="Compact-row" rowKey="compact" title={ <Translate alias="Board" word="Compact" /> } />
			<ResearchRow canEdit={canEdit} className="Plus-row" rowKey="plus" title={ <Translate alias="Board" word="Plus" /> } />
			<ResearchRow canEdit={canEdit} className="Luxus-row" rowKey="luxus" title={ <Translate alias="Board" word="Luxus" /> } />
		</div>
	);
}

export default ResearchBlock;