import React from 'react';
import { Table, FormControl } from 'react-bootstrap';

import Translate from '../../../../utils/Translate'

class BalanceSheet extends React.Component {

	handleChange = (field, value) => {
		this.props.inputChanged("balanceSheet", field, value)
	}

	render() {

		let totalAssets = 0;
		totalAssets += (!isNaN(parseFloat(this.props.balanceSheet.capitalizedDevelopmentCosts))) ? parseFloat(this.props.balanceSheet.capitalizedDevelopmentCosts) : 0
		totalAssets += (!isNaN(parseFloat(this.props.balanceSheet.productionFacilities))) ? parseFloat(this.props.balanceSheet.productionFacilities) : 0
		totalAssets += (!isNaN(parseFloat(this.props.balanceSheet.machines))) ? parseFloat(this.props.balanceSheet.machines) : 0
		totalAssets += (!isNaN(parseFloat(this.props.balanceSheet.finishedProducts))) ? parseFloat(this.props.balanceSheet.finishedProducts) : 0
		totalAssets += (!isNaN(parseFloat(this.props.balanceSheet.inventories))) ? parseFloat(this.props.balanceSheet.inventories) : 0
		totalAssets += (!isNaN(parseFloat(this.props.balanceSheet.cash))) ? parseFloat(this.props.balanceSheet.cash) : 0

		let totalLiabilities = 0;
		totalLiabilities += (!isNaN(parseFloat(this.props.balanceSheet.equity))) ? parseFloat(this.props.balanceSheet.equity) : 0
		totalLiabilities += (!isNaN(parseFloat(this.props.balanceSheet.liabilitiesNonCurrent))) ? parseFloat(this.props.balanceSheet.liabilitiesNonCurrent) : 0
		totalLiabilities += (!isNaN(parseFloat(this.props.balanceSheet.liabilitiesCurrent))) ? parseFloat(this.props.balanceSheet.liabilitiesCurrent) : 0
		
		return (
			<Table bordered>
				<thead>
					<tr>
						<th colSpan="2"><div className="bold"><Translate alias="Forms" word="Assets" /></div></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Capitalized development costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.capitalizedDevelopmentCosts }
								onChange={ (e) => this.handleChange("capitalizedDevelopmentCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Production facilities" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.productionFacilities }
								onChange={ (e) => this.handleChange("productionFacilities", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Machines" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.machines }
								onChange={ (e) => this.handleChange("machines", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Finished products" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.finishedProducts }
								onChange={ (e) => this.handleChange("finishedProducts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Inventories" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.inventories }
								onChange={ (e) => this.handleChange("inventories", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Cash and cash equivalents" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.cash }
								onChange={ (e) => this.handleChange("cash", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Total" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ totalAssets }
								disabled
							/>
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
				</tbody>
				<thead>
					<tr>
						<th colSpan="2"><div className="bold"><Translate alias="Forms" word="Liabilities" /></div></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Capital/Equity" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.equity }
								onChange={ (e) => this.handleChange("equity", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Liabilities non-current" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.liabilitiesNonCurrent }
								onChange={ (e) => this.handleChange("liabilitiesNonCurrent", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Liabilities current" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.liabilitiesCurrent }
								onChange={ (e) => this.handleChange("liabilitiesCurrent", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Total" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ totalLiabilities }
								disabled
							/>
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}

}

export default BalanceSheet;