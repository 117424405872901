import React, { useEffect, useState } from "react";
import {connect} from "react-redux";

// Dispatcher
import mapDispatchToProps from "../../../../../reducers/dispatchers";
import * as RealtimeConnection from "../../../RealtimeConnection";

// Components
import ColoredDroppableField from "./ColoredDroppableField";

const ColoredDroppableHorizontalField = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [id, setId] = useState(0)
	const [type, setType] = useState("")

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		setId(props.id)
	}, [props.id])

	useEffect(() => {
		setType(props.type)
	}, [props.type])

	const appendContainer = (coins, hasCard, cardValue, cardColor) => {
		props.addContainerColorField('ColorField', type, id, coins, hasCard, cardValue, cardColor, window.playerId, window.playerName)

		// Update Socket
		RealtimeConnection.addContainer("ColorField", {
			fieldType: type,
			id: id,
			coins: coins,
			hasCard: hasCard,
			cardValue: cardValue,
			cardColor: cardColor
		})
	}

	const removeContainer = (isDropToDelete) => {
		props.removeContainerColorField('ColorField', type, id)

		// Update Socket
		RealtimeConnection.removeContainer("ColorField", {
			fieldType: type,
			id: id,
			isDropToDelete: isDropToDelete
		})
	}

	const isDraggingHandler = (dragType, isDragging) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "ColorField",
			fieldType: type,
			id: id,
			isDragging: isDragging,
			type: dragType
		}
		window.socket.emit('item-dragging', dataObj);
	}

	const showContainerProperties = () => {
		props.openContainerPropertiesWithOption('FIELD', 'ColorField', type, id);
	}

	return (
		<div className="droppable-field" onClick={ showContainerProperties }>
			<ColoredDroppableField
				canEdit={canEdit}
				id={ id }
				data={ props.fieldObject }
				fieldType={type}
				appendContainer={ appendContainer }
				removeContainer={ removeContainer }
				isDraggingHandler={ isDraggingHandler }
			/>
		</div>
	)
}

export default connect(null, mapDispatchToProps)(ColoredDroppableHorizontalField);
