import React, { useState, useEffect } from "react";
import {Col, Row} from "react-bootstrap";

import Translate from '../../../../utils/Translate'

// Components
import SalesNumberBox from "./SalesNumberBox";

const SalesRow = ({ canEdit, title, object, appendPin, removePin }) => {

	const [titleTxt, setTitleTxt] = useState("")

	useEffect(() => {
		switch(title) {
			case "Nat":
				setTitleTxt(<Translate alias="Board" word="Nat" />)
				break;
			case "Int":
				setTitleTxt(<Translate alias="Board" word="Int" />)
				break
			case "IntC1":
				setTitleTxt(<Translate alias="Board" word="IntC1" />)
				break
			case "IntC2":
				setTitleTxt(<Translate alias="Board" word="IntC2" />)
				break
			default:
				break
		}
	}, [title])

	return (
		<Row className="sales-row-container">
			<Col><span className="sales-row-title">{ titleTxt }</span></Col>
			{
				object.map((value, index) =>
					<Col key={index}>
						<SalesNumberBox
							canEdit={canEdit}
							id={index}
							hasPin={value.pin}
							title={title}
							number={value.value}
							appendPin={appendPin}
							removePin={removePin}
						/>
					</Col>
				)
			}
			{
				(object.length === 1) ?
					<>
						<Col key={1}></Col>
						<Col key={2}></Col>
						<Col key={3}></Col>
					</>
					:
					null
			}
			{
				(object.length === 2) ?
					<>
						<Col key={2}></Col>
						<Col key={3}></Col>
					</>
					:
					null
			}
		</Row>
	);

}

export default SalesRow;