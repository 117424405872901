import React from "react";
import {Image} from "react-bootstrap";
import { useDrag, DragPreviewImage } from 'react-dnd';

// Images
import previewImg from "../../../../../assets/images/draggable-container-preview.png";
import containerImg from "../../../../../assets/images/container-h.png";
import coinImg from "../../../../../assets/images/dollar_coin_h.png";
import coinSilverImg from "../../../../../assets/images/dollar_coin_silver_h.png";
import coinBronzeImg from "../../../../../assets/images/dollar_coin_bronze_h.png";

import green from "../../../../../assets/images/tiled-green-card.png";
import yellow from "../../../../../assets/images/tiled-yellow-card.png";
import blue from "../../../../../assets/images/tiled-blue-card.png";
import red from "../../../../../assets/images/tiled-red-card.png";

let numberOfUpdates = 0;

export default function ColoredDraggableContainer(props) {
	const [{ isDragging }, drag, preview] = useDrag({
		item: {
			type: 'StockContainer',
			coins: props.data.coins,
			id: props.id,
			hasCard: props.data.hasCard,
			cardValue: props.data.cardValue,
			cardColor: props.data.cardColor
		},
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			/*console.log("Did End");

			if (monitor.didDrop()) {
				const doppableItem = monitor.getDropResult();
				if (doppableItem.type !== null && doppableItem.type !== undefined) {
					console.log("Did End 2");
					numberOfUpdates = 0;
					props.isDraggingHandler("CONTAINER", false);
				}
				//props.removeContainer();
			}*/
		}
	});

	let canDrag = (props.canEdit) ? drag : null;
	let dragClass = "";
	if (isDragging) {
		dragClass = "dragging";

		if (numberOfUpdates === 0) {
			numberOfUpdates = 1;
			props.isDraggingHandler("CONTAINER", isDragging);
		}
	}else if (props.data.isDragging && props.data.type === "CONTAINER") {
		dragClass = "dragging";
		canDrag = null;

		if (numberOfUpdates === 1) {
			numberOfUpdates = 0;
			if (props.data.playerId === window.playerId){
				dragClass = "";
				canDrag = drag;
				props.isDraggingHandler("CONTAINER", isDragging);
			}
		}
	}

	return (
		<>
			<DragPreviewImage connect={preview} src={previewImg} />
			<div className={`containerObjClass ${dragClass}`} ref={canDrag}>
				<span className="helper"></span>
				<Image className="objModelClass" src={containerImg} />
				<div className="Coins-droppable">
					<div className="helper"></div>
					{
						(props.data.coins.map((value, index) => {
							return (
								(value === 10 &&
									<Image key={index} src={coinImg} className="coin"/>)
								|| (value === 1 &&
									<Image key={index} src={coinSilverImg} className="coin"/>)
								|| (value === 0.5 &&
									<Image key={index} src={coinBronzeImg} className="coin"/>)
							)
						}))
					}
					{
						(props.data.hasCard && props.data.cardType === "label") ?
							<div className="containerVerticalProductCardDraggable">
								{(
									(props.data.cardColor === "standard" &&
										<Image className="containerProductCardDraggableImg" src={ green } />)
									|| (props.data.cardColor === "compact" &&
										<Image className="containerProductCardDraggableImg" src={ yellow } />)
									|| (props.data.cardColor === "plus" &&
										<Image className="containerProductCardDraggableImg" src={ blue } />)
									|| (props.data.cardColor === "luxus" &&
										<Image className="containerProductCardDraggableImg" src={ red } />)
								)}
								{
									(props.data.cardValue > 0) ?
										<div className="containerProductCardValue">{ props.data.cardValue }</div>
										:
										null
								}
							</div>
							:
							null
					}
				</div>
			</div>
		</>
	);
}