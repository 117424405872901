import React, { useState } from "react";
import { useDrag, DragPreviewImage, useDrop } from "react-dnd";
import { Image } from "react-bootstrap";

// Constants
import items from "../../../utils/items";

// Components
import DraggableCoin from "./DraggableCoin";

// Images
import previewImg from "../../../assets/images/draggable-container-preview.png";
import horizontalContainerImg from "../../../assets/images/container-h.png";
import verticalContainerImg from "../../../assets/images/container-v.png";
import flipContainerImg from "../../../assets/images/box-container-v-flipped.png";
import machineCardImg from "../../../assets/images/machine-img-h.png";
import verticalMachineCardImg from "../../../assets/images/machine-img-v.png";
import factoryCardImg from "../../../assets/images/factory-img-h.png";
import verticalFactoryCardImg from "../../../assets/images/factory-img-v.png";
import flippedFactoryCard from "../../../assets/images/factory-card.png";

import tilledGreen from "../../../assets/images/tiled-green-card.png";
import tilledYellow from "../../../assets/images/tiled-yellow-card.png";
import tilledBlue from "../../../assets/images/tiled-blue-card.png";
import tilledRed from "../../../assets/images/tiled-red-card.png";

let numberOfUpdates = 0;

export default function DraggableContainer(props) {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type:
        props.containerType !== undefined
          ? props.containerType
          : items.container,
      coins: props.data.coins,
      hasCard: props.data.hasCard,
      cardValue: props.data.cardValue,
      cardColor: props.data.cardColor,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      let isDropToDelete = false;

      const doppableItem = monitor.getDropResult();
      if (doppableItem !== null) {
        isDropToDelete = doppableItem.droppableType === "delete" ? true : false;
      }

      if (monitor.didDrop()) {
        if (props.productionType) {
          let droppedItem = monitor.getDropResult();
          if (droppedItem !== null && droppedItem.type !== undefined) {
            props.removeContainer(
              props.productionType,
              props.id,
              isDropToDelete
            );
          }
        } else {
          props.id !== undefined
            ? props.removeContainer(props.id, isDropToDelete)
            : props.removeContainer(isDropToDelete);
        }

        numberOfUpdates = 0;
        // Update Dragging Status
        let dragObjData = {};
        if (props.id !== undefined) dragObjData.id = props.id;
        if (props.productionType !== undefined)
          dragObjData.fieldType = props.productionType;

        props.isDraggingHandler("CONTAINER", false, dragObjData);
      }
    },
  });

  let listOfDroppable = [
    items.coin,
    items.machineLbl,
    items.factoryLbl,
    items.productLbl,
  ];
  if (props.data.isFlip) {
    listOfDroppable = [items.factoryLbl];
  }

  const [{ containerId }, drop] = useDrop({
    accept: listOfDroppable,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      containerId: monitor.getHandlerId(),
    }),
    drop: (item, monitor) => {
      if (monitor.getHandlerId() === item.containerId) {
        return {};
      }

      switch (item.type) {
        case items.machineLbl:
          if (props.productionType === "machine") {
            if (item.count > 0) {
              props.addCard(props.productionType, props.id);
              return item;
            }
          }
          return {};
        case items.factoryLbl:
          if (props.productionType === "factory") {
            if (item.count > 0) {
              props.addCard(props.productionType, props.id);
              return item;
            }
          }
          return {};
        case items.productLbl:
          if (props.productionType === "product") {
            props.addCard(
              props.productionType,
              props.id,
              item.value,
              item.color
            );
            return item;
          }
          return {};
        default:
          if (props.data.isFlip) {
            return {};
          }
          if (props.data.coins.length < 20) {
            if (item.count !== undefined) {
              // From Starting Kit
              if (item.count >= item.value) {
                if (props.productionType) {
                  props.addCoin(props.productionType, item.value, props.id);
                } else {
                  props.id !== undefined
                    ? props.addCoin(props.id, item.value)
                    : props.addCoin(item.value);
                }
              }
            } else {
              if (props.productionType) {
                props.addCoin(props.productionType, item.value, props.id);
              } else {
                props.id !== undefined
                  ? props.addCoin(props.id, item.value)
                  : props.addCoin(item.value);
              }
            }

            return item;
          } else {
            return {};
          }
      }
    },
  });

  const coinsContainerClass = props.isHorizontal
    ? "Coins-droppable"
    : "Coins-vertical-droppable";
  const containerImg = props.isHorizontal
    ? horizontalContainerImg
    : verticalContainerImg;
  let factoryImg = props.isHorizontal ? factoryCardImg : verticalFactoryCardImg;
  const machineImg = props.isHorizontal
    ? machineCardImg
    : verticalMachineCardImg;
  if (props.data.isFlip) {
    factoryImg = flippedFactoryCard;
  }

  let canPlayerDrag = props.canEdit ? drag : null;
  let canPlayerDrop = props.canEdit ? drop : null;

  let dragClass = "";
  if (isDragging) {
    dragClass = "dragging";

    if (numberOfUpdates === 0) {
      numberOfUpdates = 1;

      let dragObjData = {};
      if (props.id !== undefined) {
        dragObjData.id = props.id;
      }
      if (props.productionType !== undefined) {
        dragObjData.fieldType = props.productionType;
      }
      props.isDraggingHandler("CONTAINER", isDragging, dragObjData);
    }
  } else if (props.data.isDragging && props.data.type === "CONTAINER") {
    dragClass = "dragging";
    canPlayerDrag = null;
    canPlayerDrop = null;

    if (props.data.playerId === window.playerId) {
      if (numberOfUpdates === 1) {
        numberOfUpdates = 0;

        let dragObjData = {};
        if (props.id !== undefined) {
          dragObjData.id = props.id;
        }
        if (props.productionType !== undefined) {
          dragObjData.fieldType = props.productionType;
        }
        props.isDraggingHandler("CONTAINER", isDragging, dragObjData);
      }
    }
  }
  const [isShown, setIsShown] = useState(false);
  let displayedCoins = 0;
  for (const coin of props.data.coins) {
    displayedCoins += coin;
  }
  console.log(displayedCoins);
  return (
    <>
      <DragPreviewImage connect={preview} src={previewImg} />
      <div
        className="coins-sum"
        style={
          isShown && props.data.coins
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <p style={{ color: "#d1d1d1", margin: 0 }}>{displayedCoins}</p>
      </div>
      {props.data.isFlip ? (
        <div
          className="containerObjClass"
          ref={canPlayerDrag}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          <Image className="objModelClass" src={flipContainerImg} />
          <div className={coinsContainerClass} ref={canPlayerDrop}>
            {props.data.hasCard && props.data.cardType === "factory" ? (
              <Image className="Container-Card-2" src={factoryImg} />
            ) : null}
          </div>
        </div>
      ) : (
        <div
          className={`containerObjClass ${dragClass}`}
          ref={canPlayerDrag}
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          <span className="helper"></span>
          <Image className="objModelClass" src={containerImg} />
          <div className={coinsContainerClass} ref={canPlayerDrop}>
            {props.isHorizontal ? <span className="helper"></span> : null}
            {props.data.coins.map((value, index) => (
              <DraggableCoin
                containerId={containerId}
                canEdit={props.canEdit}
                key={index}
                id={props.id}
                index={index}
                value={value}
                draggingType={props.data.type}
                coinDraggingIndex={
                  props.data.coinsDraggingIndex !== undefined
                    ? props.data.coinsDraggingIndex
                    : -1
                }
                playerId={props.data.playerId}
                isLastCoin={
                  props.data.coins.length === index + 1 && (index + 1) % 5 === 0
                    ? true
                    : false
                }
                isHorizontal={props.isHorizontal}
                productionType={props.productionType}
                removeCoin={props.removeCoin}
                isDraggingHandler={props.isDraggingHandler}
              />
            ))}
            {props.data.hasCard && props.data.cardType === "machine" ? (
              <Image className="Container-Card" src={machineImg} />
            ) : null}
            {props.data.hasCard && props.data.cardType === "factory" ? (
              <Image className="Container-Card" src={factoryImg} />
            ) : null}
            {props.data.hasCard && props.data.cardType === "label" ? (
              <div className="containerProductCardDraggable">
                {(props.data.cardColor === "standard" && (
                  <Image
                    className="containerProductCardDraggableImg"
                    src={tilledGreen}
                  />
                )) ||
                  (props.data.cardColor === "compact" && (
                    <Image
                      className="containerProductCardDraggableImg"
                      src={tilledYellow}
                    />
                  )) ||
                  (props.data.cardColor === "plus" && (
                    <Image
                      className="containerProductCardDraggableImg"
                      src={tilledBlue}
                    />
                  )) ||
                  (props.data.cardColor === "luxus" && (
                    <Image
                      className="containerProductCardDraggableImg"
                      src={tilledRed}
                    />
                  ))}
                {props.data.cardValue > 0 ? (
                  <div className="containerProductCardValue">
                    {props.data.cardValue}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

/*
isHorizontal
<x-model class={props.objectClasses} src="/models/test-box.glb"></x-model>
<x-model class={props.objectClasses} src="/models/Box3-3.glb"></x-model>
 */
