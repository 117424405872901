import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {Col, Container, Image, Row} from "react-bootstrap";

import Translate from "../../utils/Translate"

// Images
import kitsIcon from "../../assets/images/kits-icon.png";

// Dispatchers
import mapDispatchToProps from "../../reducers/dispatchers";
import * as RealtimeConnection from "./RealtimeConnection";

// Constants
import coins from "../../utils/coins";

// Components
import MainDraggableContainer from "./StarterKit/MainDraggableContainer";
import MainDraggableToken from "./StarterKit/MainDraggableToken";
import MainDraggableBlackToken from "./StarterKit/MainDraggableBlackToken";
import MainDraggablePin from  "./StarterKit/MainDraggablePin";
import MainDraggableCoin from "./StarterKit/MainDraggableCoin";
import MainDraggableFactory from "./StarterKit/MainDraggableFactory";
import MainDraggableMachine from "./StarterKit/MainDraggableMachine";
import MainDraggableProductCard from "./StarterKit/MainDraggableProductCard";
import MainDroppableDelete from "./StarterKit/MainDroppableDelete";

const KitsAndObjects = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [isMenuOpened, setIsMenuOpened] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	const toggleMenu = () => {
		if (isMenuOpened) {
			setIsMenuOpened(false)
			updateUI("MENU", "close");
		}else{
			setIsMenuOpened(true)
			updateUI("MENU", "open");
		}
	}

	const updateUI = (panelType, status) => {
		const appParentContainer = document.getElementsByClassName("main-app-bg");
		const appContainer = document.getElementsByClassName("App");
		const menuContainer = document.getElementsByClassName("kitContainer");

		if (status === "close") {
			appContainer[0].classList.remove('moveLeft');
			menuContainer[0].classList.remove('fullWidth');
		}else{
			appParentContainer[0].classList.remove("width");
			appContainer[0].classList.remove('moveRight');
			appContainer[0].classList.add('moveLeft');

			if (panelType === "MENU") {
				menuContainer[0].classList.add('fullWidth');
			}else{
				this.setState({ isMenuOpened: false }, () => {
					menuContainer[0].classList.remove('fullWidth');
				});
			}
		}
	}

	const decreaseStarterKit = (type, value, color) => {
		//this.props.decreaseStarterKit(type, value);

		// Update Socket
		RealtimeConnection.decreaseStarterKit({
			type: type,
			value: value,
			color: color
		})
	}

	const increaseStarterKit = (type, value, color) => {
		//this.props.increaseStarterKit(type, value);

		// Update Socket
		RealtimeConnection.increaseStarterKit({
			type: type,
			value: value,
			color: color
		})
	}

	let lbls = [];
	let count = 0;
	if (props.startingKit.production !== undefined && Object.keys(props.startingKit.production).length > 0) {
		Object.keys(props.startingKit.production).forEach( value => {
			Object.keys(props.startingKit.production[value]).forEach((value2, index) => {
				if(props.startingKit.production[value][value2] > 0) {
					lbls.push(
						<React.Fragment key={ index }>
							{
								(count % 2 === 0) ?
									<div className="w-100"></div>
									:
									null
							}
							<Col>
								<MainDraggableProductCard
									canEdit={canEdit}
									color={value2}
									value={value}
									decreaseStarterKit={decreaseStarterKit}
								/>
							</Col>
						</React.Fragment>
					)
					count++;
				}
			})
		})
	}

	return (
		<div className={props.boardType}>
			<div className={ `kits-objects-btn`} onClick={ toggleMenu }>
				<div className="button">
					<Image src={kitsIcon} />
					<div className="text">
						<Translate alias="Board" word="Team Table" />
					</div>
				</div>
			</div>
			<div className="kitContainer">
				<div className="kits-header">
					<div className="kits-header-logo">
						<Image src={kitsIcon} />
						<div className="text">
							<Translate alias="Board" word="Team Table" />
						</div>
					</div>
				</div>
				<div className="blur">
					<div className="object-container-div">
						<div className="container-text">
							<Translate alias="Board" word="Containers" />
						</div>
						<MainDraggableContainer
							canEdit={canEdit}
						/>
					</div>
					<div className="kits-separator"></div>
					{
						(props.startingKit.coins > 0) ?
							<>
								<div className="object-coins-div">
									<div className="container-text">
										<Translate alias="Board" word="Value" />
										<div className="object-value">
											<span className="notranslate">
												{ props.startingKit.coins }
											</span>
										</div>
									</div>
									<Container>
										<Row>
											{(
												coins.map((value, index) =>
													<Col key={index}>
														<MainDraggableCoin
															canEdit={canEdit}
															decreaseStarterKit={decreaseStarterKit}
															value={value}
															count={props.startingKit.coins}
														/>
													</Col>
												)
											)}
										</Row>
									</Container>
								</div>
								<div className="kits-separator"></div>
							</>
							:
							null
					}
					<div className="object-labels-div">
						<div className="container-text">
							<Translate alias="Board" word="Labels" />
						</div>
						<Container>
							<Row>
								<Col>
									<MainDraggableFactory
										canEdit={canEdit}
										decreaseStarterKit={decreaseStarterKit}
										count={props.startingKit.factory}
									/>
								</Col>
								<Col>
									<MainDraggableMachine
										canEdit={canEdit}
										decreaseStarterKit={decreaseStarterKit}
										count={props.startingKit.machine}
									/>
								</Col>
								<div className="w-100"></div>
								<Col>
									<div className="object-value-center">
										<span className="notranslate">
											{ props.startingKit.factory }
										</span>
									</div>
								</Col>
								<Col>
									<div className="object-value-center">
										<span className="notranslate">
											{ props.startingKit.machine }
										</span>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div className="kits-separator"></div>
					{
						(props.startingKit.pins > 0) ?
							<>
								<div className="object-pins-div">
									<div className="container-text">
										<Translate alias="Board" word="Pins" />
										<div className="object-value">
											<span className="notranslate">
												{props.startingKit.pins}
											</span>
										</div>
									</div>
									<div className="draggable-pins-container">
										<Container>
											<Row>
												{
													Array.apply(null, { length: props.startingKit.pins }).map((e, i) => (
														<React.Fragment key={i}>
															{
																(i % 6 === 0) ?
																	<div className="w-100"></div>
																	:
																	null
															}
															<MainDraggablePin
																key={i}
																canEdit={canEdit}
																count={props.startingKit.pins}
																decreaseStarterKit={decreaseStarterKit}
															/>
														</React.Fragment>
													))
												}
											</Row>
										</Container>
									</div>
								</div>
								<div className="kits-separator"></div>
							</>
							:
							null
					}
					{
						(props.startingKit.tokens > 0 || props.startingKit.blackTokens) ?
							<>
								<div className="object-tokens-div">
									<div className="container-text">
										<Translate alias="Board" word="Liability tokens" />
									</div>
									<Container>
										<Row>
											{
												(props.startingKit.tokens > 0) ?
													<Col>
														<MainDraggableToken
															canEdit={canEdit}
															decreaseStarterKit={decreaseStarterKit}
															count={ props.startingKit.tokens }
															value={ props.startingKit.tokens }
														/>
													</Col>
													:
													null
											}
											{
												(props.startingKit.blackTokens > 0) ?
													<Col>
														<MainDraggableBlackToken
															canEdit={canEdit}
															decreaseStarterKit={decreaseStarterKit}
															count={ props.startingKit.blackTokens }
															value={ props.startingKit.blackTokens }
														/>
													</Col>
													:
													null
											}
										</Row>
									</Container>
								</div>
								<div className="kits-separator"></div>
							</>
							:
							null
					}
					{
						(lbls.length > 0) ?
							<>
								<div className="object-pins-div">
									<div className="container-text">
										<Translate alias="Board" word="Production units" />
									</div>
									<div style={{ "display": "flex" }}>
										<div style={{ "width": "65%", "margin": "0 auto" }}>
											<Container>
												<Row>
													{ lbls }
												</Row>
											</Container>
										</div>
									</div>
								</div>
								<div className="kits-separator"></div>
							</>
							:
							null
					}
					<div className="object-delete-div">
						<div className="container-text">
							<Translate alias="Board" word="Delete" />
						</div>
						<MainDroppableDelete
							increaseStarterKit={increaseStarterKit}
						/>
						<div className="clearfix"></div>
					</div>
					<div className="kits-separator"></div>
					<div style={{ height: "30px" }}></div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		startingKit: state.startingKit
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(KitsAndObjects);