import React from 'react';
import { Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap';

import Translate from '../../../../utils/Translate'

class StrategyPaperProduction extends React.Component {

	optionChanged = (field, value) => {
		this.props.optionChanged("production", this.props.option, field, value)
	}

	render() {
		let title = ""
		switch (this.props.index) {
			case 0:
			case 1:
			case 2:
				title= "National";
				break;
			case 3:
			case 4:
			case 5:
				title= "International";
				break;
			case 6:
			case 7:
				title= "Intercontinental 1";
				break;
			case 8:
			case 9:
				title= "Intercontinental 2";
				break;
			default:
				break;
		}
		return (
			<Container>
				<Row>
					<Col>
						<div className="bold">{ (this.props.index + 1) } <Translate alias="Forms" word={ title } /></div>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Product" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.product }
								onChange={ (e) => this.optionChanged("product", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm"><Translate alias="Forms" word="Units" /></InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.units }
								onChange={ (e) => this.optionChanged("units", e.target.value) }
								disabled={ !this.props.status }
							/>
						</InputGroup>
					</Col>
				</Row>
			</Container>
		);
	}

}

export default StrategyPaperProduction;