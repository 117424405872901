//const productionLblColors = {"green":"STANDARD", "yellow":"COMPACT", "blue":"PLUS", "red":"LUXUS"}

// Send Message
export const sendMessage = (options) => {
	window.socket.emit("add-message", {
		sessionId: window.sessionId,
		teamId: window.teamId,
		playerId: window.playerId,
		playerName: window.playerName,
		message: options.message
	});
}

// Update Team Name
export const changeTeamName = (options) => {
	window.socket.emit("change-team", {
    teamId: window.teamId,
    value: options.value,
    sessionId: window.sessionId,
  });
}

// Step Back
export const oneStepBack = () => {
	window.socket.emit("backward-step", {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId
	});
}

// Add Container
export const addContainer = (alias, options) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: alias,
		id: options.id,
		coins: options.coins
	}
	if (options.fieldType !== undefined) {
		dataObj.fieldType = options.fieldType;
	}
	if (options.hasCard !== undefined) {
		dataObj.hasCard = options.hasCard;
	}
	if (options.cardValue !== undefined) {
		dataObj.cardValue = options.cardValue;
	}
	if (options.cardColor !== undefined) {
		dataObj.cardColor = options.cardColor;
	}

	window.socket.emit('add-container', dataObj);
}

// Remove Container
export const removeContainer = (alias, options) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: alias,
		id: options.id,
		isDropToDelete: options.isDropToDelete
	}
	if (options.fieldType !== undefined) {
		dataObj.fieldType = options.fieldType;
	}
	if (options.color !== undefined) {
		dataObj.color = options.color;
	}

	window.socket.emit('remove-container', dataObj);
}

// Add Coin
export const addCoin = (alias, options) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: alias,
		id: options.id,
		value: options.value
	}
	if (options.fieldType !== undefined) {
		dataObj.fieldType = options.fieldType;
	}

	window.socket.emit('add-coin', dataObj);
}

// Remove Coin
export const removeCoin = (alias, options) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: alias,
		id: options.id,
		index: options.index,
		isDropToDelete: options.isDropToDelete
	}
	if (options.fieldType !== undefined) {
		dataObj.fieldType = options.fieldType;
	}

	window.socket.emit('remove-coin', dataObj);
}

// Add Multiple Coins
export const addMultipleCoins = (alias, options) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: alias,
		id: options.id,
		coins: options.coins
	}
	if (options.fieldType !== undefined) {
		dataObj.fieldType = options.fieldType;
	}

	window.socket.emit('add-coins', dataObj);
}

// Update Coins Array
export const updateCoins = (alias, options) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: alias,
		id: options.id,
		coins: options.coins
	}
	if (options.fieldType !== undefined) {
		dataObj.fieldType = options.fieldType;
	}

	window.socket.emit('update-coins', dataObj);
}

// Add Token
export const addToken = (options) => {
	window.socket.emit('add-token', {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		type: options.type,
		value: options.value,
		droppedTokenType: options.tokenType
	});
}

// Remove Token
export const removeToken = (options) => {
	window.socket.emit('remove-token', {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		type: options.type,
		value: options.value
	});
}

// Add Pin
export const addPin = (alias, options) => {
	window.socket.emit('add-pin', {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: alias,
		title: options.title,
		speed: options.speed,
		id: options.id
	});
}

// Remove Pin
export const removePin = (alias, options) => {
	window.socket.emit('remove-pin', {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: alias,
		title: options.title,
		speed: options.speed,
		id: options.id,
		isDropToDelete: options.isDropToDelete
	});
}

// Add Card
export const addCard = (alias, options) => {
	window.socket.emit('add-card', {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		alias: alias,
		id: options.id,
		index: options.index,
		optionType: options.optionType,
		color: options.color,
		value: options.value,
	});
}

// Purchase Factory
export const purchaseFactory = (options) => {
	window.socket.emit("purchase-factory", {
		sessionId: window.sessionId,
		teamId: window.teamId,
		playerId: window.playerId,
		playerName: window.playerName,
		id: options.id
	});
}

// Rent Factory
export const rentFactory = (options) => {
	window.socket.emit("rent-factory", {
		sessionId: window.sessionId,
		teamId: window.teamId,
		playerId: window.playerId,
		playerName: window.playerName,
		id: options.id
	});
}


export const formChangeYear = (options) => {
	window.socket.emit("update-form-year", {
		sessionId: window.sessionId,
		teamId: window.teamId,
		playerId: window.playerId,
		playerName: window.playerName,
		year: options.year
	});
}

// Update Form
export const updateForm = (options) => {
	window.socket.emit("update-form", {
		sessionId: window.sessionId,
		teamId: window.teamId,
		playerId: window.playerId,
		playerName: window.playerName,
		year: options.year,
		type: options.type,
		section: options.section,
		field: options.field,
		option: options.option,
		value: options.value
	});
}


// Update Starter Kit
export const decreaseStarterKit = (options) => {
	window.socket.emit("decrease-starter-kit", {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		type: options.type,
		value: (options.type === "production") ? 1 : options.value,
		number: options.value, // for production labels only
		color: options.color // for production labels only
	});
}

export const increaseStarterKit = (options) => {
	window.socket.emit("increase-starter-kit", {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		type: options.type,
		value: (options.type === "production") ? 1 : options.value,
		number: options.value, // for production labels only
		color: options.color // for production labels only
	});
}