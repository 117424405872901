import React, { useState, useEffect } from "react";
import {Col, Container, Row} from "react-bootstrap";

import ColoredDroppableHorizontalField from "./Fields/ColoredDroppableHorizontalField";

const StockGreenBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])
	
	const droppableFieldsList = props.data.map((value, key) => {
		if (key % 4 === 0) {
			return <React.Fragment key={key}>
				<div className="w-100"></div>
				<Col><ColoredDroppableHorizontalField canEdit={canEdit} id={key} type="standard" fieldObject={value} /></Col>
			</React.Fragment>
		}

		return <Col key={key}><ColoredDroppableHorizontalField canEdit={canEdit} id={key} type="standard" fieldObject={value} /></Col>
	})

	return (
		<Container className="Stock-green-block colored-block">
			<Row>
				{ droppableFieldsList }
			</Row>
		</Container>
	)
}

export default StockGreenBlock;

/*
	<Col className="bottom">
		<ColoredDroppableHorizontalField />
	</Col>
	<Col className="bottom">
		<ColoredDroppableHorizontalField />
	</Col>
	<Col className="bottom">
		<ColoredDroppableHorizontalField />
	</Col>
	<Col className="bottom">
		<ColoredDroppableHorizontalField />
	</Col>
</Row>
<Row>
	<Col><ColoredDroppableHorizontalField /></Col>
	<Col><ColoredDroppableHorizontalField /></Col>
	<Col><ColoredDroppableHorizontalField /></Col>
	<Col><ColoredDroppableHorizontalField /></Col>
 */