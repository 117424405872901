import React from 'react';
import { FormControl } from 'react-bootstrap';

class StrategyPaperFormControl extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			field: props.field
		}
	}

	handleChange = (value) => {
		this.props.inputChanged(this.state.field, value)
	}

	render() {
		return (
			<FormControl 
				aria-label="Small" 
				size="sm" 
				value={ this.props.value } 
				onChange={ (e) => this.handleChange(e.target.value)} 
				disabled={ !this.props.status }
			/>
		);
	}

}

export default StrategyPaperFormControl;