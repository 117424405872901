import React, { useEffect, useState } from "react";

// Components
import TokenProperties from "./TokenProperties";
import ContainerProperties from "./ContainerProperties";

const Properties = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	return (
		<React.Fragment>
			{
				(props.properties.type === "token")?
					<TokenProperties 
							canEdit={canEdit} 
							startingKit={props.startingKit}
							properties={props.properties}
							board={props.board}
							closePanel={props.closePanel}
						/>
					:
					<ContainerProperties
							canEdit={canEdit}
							startingKit={props.startingKit}
							properties={props.properties}
							board={props.board}
							closePanel={props.closePanel}
						/>
			}
		</React.Fragment>
	);
}

export default Properties;