import React, { Component } from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { Image } from 'react-bootstrap';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'

import axios from 'axios';

// Constant
import urls from "./utils/urls";

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
//import './css/SimFlex/ScreenSize.css';
import 'rc-tooltip/assets/bootstrap.css';

// Components
import AppProvider from "./AppProvider"

import reducer from "./reducers/index";


// Images
import loaderImg from "./assets/images/loader.gif";
import HomeComponent from "./HomeComponent";

const store = createStore(reducer);

function findGetParameter(parameterName) {
	let result = null,
		tmp = [];
	let items = window.location.search.substr(1).split("&");
	for (let index = 0; index < items.length; index++) {
		tmp = items[index].split("=");
		if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
	}
	return result;
}

window.sessionId = parseInt(findGetParameter("session_id"));
window.playerId = parseInt(findGetParameter("user_id"));
window.adminId = parseInt(findGetParameter("admin_id"));
window.teamId = parseInt(findGetParameter("team_id"));
window.token = findGetParameter("token");

class App extends Component{

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			isAuthorized: false,
			notAuthorizedMessage: "",
			canEdit: false,
			isLoggedOut: false,
			teamName: "",
			languages: []
		}
	}

	componentDidMount() {
		this.fetchAuthenticate();

		/*this.setState({
			loading: false,
			isAuthorized: true,
			canEdit: true,
			isAdmin: 1,
			notAuthorizedMessage: ""
		});*/
	}

	async fetchAuthenticate() {
		const result = await axios.post(urls.authenticate, {
			playerId: (!isNaN(window.playerId)) ? window.playerId : 0,
			adminId: (!isNaN(window.adminId)) ? window.adminId : 0,
			teamId: window.teamId,
			token: window.token,
			sessionId: window.sessionId,
			langId: localStorage.langId
		}, { headers: {"content-type": "application/json"} })

		console.log(result.data);
		
		if (result.data.success) {
			let response = result.data;
			if (!isNaN(window.adminId)) {
				window.playerId = window.adminId
				window.playerName = response.player.firstName + " " + response.player.lastName;
			}else{
				window.playerName = response.player.name;
			}

			window.staticWords = response.localizations.staticWords

			this.setState({
				loading: false,
				isAuthorized: true,
				languages: response.languages,
				teamName: (response.teamData) ? response.teamData.name : response.player.Teams[0].name,
				canEdit: response.canEdit, //response.data.can_edit,
				isAdmin: (!isNaN(window.adminId)) ? true : false //response.data.is_admin,
			});
		}else{
			this.setState({
				loading: false,
				isAuthorized: false,
				notAuthorizedMessage: result.data.message
			})
		}
	}

	logout = () => {
		this.setState({
			loading: true,
			isAuthorized: false,
			canEdit: false
		}, ()=>{
			this.logoutSession();
		});
	}
	async logoutSession() {
		const result = await axios.post(urls.logout, {
			user_id: window.playerId,
			token: window.token
		})

		let response = result.data;
		if (response.status === 200) {
			this.setState({ loading: false, isLoggedOut: true });
		}else{
			window.location.reload(false);
		}
	}

	render() {
		return (
			<Provider store={store}>
				<I18nextProvider i18n={i18n}>
				{
					(this.state.isAuthorized) ?
						<AppProvider
							canEdit={this.state.canEdit}
							isAdmin={this.state.isAdmin}
							logoutAction={this.logout}
							teamName={ this.state.teamName }
							languages={ this.state.languages }
						/>
						:
						<div className="AuthorizationContainer">
							<div className="relativeContainer">
								{
									(this.state.loading) ?
										<div className="NotAuthorizedContainerLoaderImg">
											<div className="loaderContainer">
												<Image src={loaderImg} />
											</div>
										</div>
										:
										<HomeComponent isLoggedOut={this.state.isLoggedOut} notAuthorizedMessage={this.state.notAuthorizedMessage} />
								}
							</div>
						</div>
				}
				</I18nextProvider>
			</Provider>
		);
	}

}

export default App;

/*
{
	(this.state.canEdit) ?
		<ChatComponent
			canEdit={this.state.canEdit}
			sessionId={this.state.sessionId}
			userId={this.state.userId}
			teamId={this.state.teamId}
		/>
		:
		null
}
 */