import React, { useEffect, useState } from "react";
import {Row, Col} from "react-bootstrap";

import Translate from '../../../../utils/Translate'

// Components
import PurchaseRow from "./PurchaseRow";

const PurchaseBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	return (
		<div className="Purchase-block-container">
			<Row>
				<Col>
					<div className="Purchase-bold-text Purchase-title-text-size Same-line">
						<Translate alias="Board" word="Purchasing" />
					</div>
					<div className="Purchase-subtitle-text-size">
						<Translate alias="Board" word="material costs/unit" />
					</div>
				</Col>
				<Col>
					<div style={{ height: 18 }}> &nbsp; </div>
					<div className="Purchase-subtitle-text-size"><Translate alias="Board" word="inventories" /></div>
				</Col>
			</Row>
			<PurchaseRow canEdit={canEdit} className="Standard-row" title="STANDARD" />
			<PurchaseRow canEdit={canEdit} className="Compact-row" title="COMPACT" />
			<PurchaseRow canEdit={canEdit} className="Plus-row" title="PLUS" />
			<PurchaseRow canEdit={canEdit} className="Luxus-row" title="LUXUS" />
		</div>
	)
}

export default PurchaseBlock;