import React from "react";
import { useDrag, DragPreviewImage } from 'react-dnd';
import {Col, Image} from "react-bootstrap";

// Constants
import items from "../../../utils/items";

// Images
import pin from "../../../assets/images/pushpin2.png";
import previewImg from "../../../assets/images/pushpin-preview.png";

export default function MainDraggablePin(props) {

	const [, drag, preview] = useDrag({
		item: { type: items.pin, count: props.count },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			if (monitor.didDrop() && dropResult.count > 0) {
				props.decreaseStarterKit("pins", 1);
			}
		}
	});

	const canDrag = (props.canEdit) ? drag : null
	return (
		<Col xs={2}>
			<DragPreviewImage connect={preview} src={previewImg} />
			<div className="pinDraggable" ref={canDrag}>
				<Image src={pin} />
			</div>
		</Col>
	);
}