import React, { useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";

// Components
import FinanceBlock from "./FinanceBlock/FinanceBlock";
import ResearchBlock from "./ResearchBlock/ResearchBlock";
import PurchaseBlock from "./PurchaseBlock/PurchaseBlock";
import ProductionBlock from "./ProductionBlock/ProductionBlock";
import SalesStockBlock from "./SalesStockBlock";

const CenterBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	return (
		<div className="Center-block">
			<Row>
				<div className="Finance-container-margin">
					<FinanceBlock canEdit={canEdit} />
				</div>
				<div className="Research-Purchasing-container">
					<div className="Research-Container">
						<ResearchBlock canEdit={canEdit} />
					</div>
					<div className="Purchase-Container">
						<PurchaseBlock canEdit={canEdit} />
					</div>
				</div>
			</Row>
			<Row>
				<Col>
					<ProductionBlock canEdit={canEdit} />
				</Col>
			</Row>
			<Row>
				<Col>
					<SalesStockBlock canEdit={canEdit} />
				</Col>
			</Row>
		</div>
	)
}

export default CenterBlock;