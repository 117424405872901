import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import language3 from './localization/3/translate.json'
import language4 from './localization/4/translate.json'
import language5 from './localization/5/translate.json'
import language6 from './localization/6/translate.json'
import language7 from './localization/7/translate.json'
import language8 from './localization/8/translate.json'
import language16 from './localization/16/translate.json'
import language17 from './localization/17/translate.json'
//ANCHOR_IMPORT_END

i18n
.use(LanguageDetector)
.use(XHR)
.use(initReactI18next)
.init({
	resources: {
		"3": { translations: language3 },
		"4": { translations: language4 },
		"5": { translations: language5 },
		"6": { translations: language6 },
		"7": { translations: language7 },
	"8": { translations: language8 },
	"16": { translations: language16 },
	"17": { translations: language17 },
	//ANCHOR_RESOURCE_END
	},
	/* default language when load the website in browser */
	//lng: "de",
	/* When react i18next not finding any language to as default in borwser */
	fallbackLng: "3",
	/* debugger For Development environment */
	debug: true,
	ns: ["translations"],
	defaultNS: "translations",
	keySeparator: "__",
	nsSeparator: false,
	interpolation: {
		escapeValue: false,
		formatSeparator: ","
	},
	react: {
		wait: true,
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default'
	}
})

export default i18n;