import React from 'react';
import { Row, Col, DropdownButton, Dropdown, ButtonGroup, Container } from 'react-bootstrap';

import Translate from '../../../../utils/Translate'

class StrategyPaperFixedAsset extends React.Component {

	optionChanged = (field, value) => {
		this.props.optionChanged("fixedAssets", this.props.option, field, value)
	}

	render() {
		let title = ""
		switch (this.props.index) {
			case 0:
			case 1:
			case 2:
				title= "National";
				break;
			case 3:
			case 4:
			case 5:
				title= "International";
				break;
			case 6:
			case 7:
				title= "Intercontinental 1";
				break;
			case 8:
			case 9:
				title= "Intercontinental 2";
				break;
			default:
				break;
		}

		const purchaseRentTxt = <Translate alias="Forms" word="Purchase / Rent" />
		const machineDefaultOptionTxt = <Translate alias="Forms" word="f/m/s" />;
		
		let selectedOption;
		if (this.props.field.factory.toLowerCase() === "purchase") {
			selectedOption = <Translate alias="Forms" word="Purchase" />
		}else{
			selectedOption = <Translate alias="Forms" word="Rent" />
		}

		let selectedMachineOption;
		if (this.props.field.machine.toLowerCase() === "f") {
			selectedMachineOption = <Translate alias="Forms" word="Fast" />
		}else if (this.props.field.machine.toLowerCase() === "m") {
				selectedMachineOption = <Translate alias="Forms" word="Medium" />
		}else if (this.props.field.machine.toLowerCase() === "s") {
			selectedMachineOption = <Translate alias="Forms" word="Slow" />
		}

		return (
			<Container style={{ marginBottom: 10 }}>
				<Row>
					<Col md="12" className="removeLeftPadding">
						<div className="bold">{ (this.props.index + 1) } <Translate alias="Forms" word={ title } /></div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div>
							<DropdownButton
								disabled={ !this.props.status }
								as={ButtonGroup}
								variant="outline-secondary"
								className={ (this.props.field.factory !== "") ? "whiteDropdown" : "" }
								title={ (this.props.field.factory !== "") ? selectedOption : purchaseRentTxt }
								size="sm"
							>
								<Dropdown.Item eventKey="" onClick={ this.optionChanged.bind(this, "factory", "") }>-</Dropdown.Item>
								<Dropdown.Item eventKey="purchase" onClick={ this.optionChanged.bind(this, "factory", "Purchase") }><Translate alias="Forms" word="Purchase" /></Dropdown.Item>
								<Dropdown.Item eventKey="rent" onClick={ this.optionChanged.bind(this, "factory", "Rent") }><Translate alias="Forms" word="Rent" /></Dropdown.Item>
							</DropdownButton>
						</div>
					</Col>
					<Col>
						<div style={{ paddingLeft: 10 }}>
							<DropdownButton
								disabled={ !this.props.status }
								as={ButtonGroup}
								variant="outline-secondary"
								className={ (this.props.field.machine !== "") ? "whiteDropdown" : "" }
								title={ (this.props.field.machine !== "") ? selectedMachineOption : machineDefaultOptionTxt }
								size="sm"
							>
								<Dropdown.Item eventKey="" onClick={ this.optionChanged.bind(this, "machine", "") }>-</Dropdown.Item>
								<Dropdown.Item eventKey="fast" onClick={ this.optionChanged.bind(this, "machine", "f") }><Translate alias="Forms" word="Fast" /></Dropdown.Item>
								<Dropdown.Item eventKey="medium" onClick={ this.optionChanged.bind(this, "machine", "m") }><Translate alias="Forms" word="Medium" /></Dropdown.Item>
								<Dropdown.Item eventKey="slow" onClick={ this.optionChanged.bind(this, "machine", "s") }><Translate alias="Forms" word="Slow" /></Dropdown.Item>
							</DropdownButton>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}

}

export default StrategyPaperFixedAsset;