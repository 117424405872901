import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {Row, Col, Image} from 'react-bootstrap';

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";
import * as RealtimeConnection from "../RealtimeConnection";

// CSS
import '../../../css/SimFlexMAN/RightBlock.css';

// Images
import rightArrow from "../../../assets/images/right-arrow.svg";

// Components
import BottomRightBlockRowField from "./BottomRightBlockRowField";

const BottomRightBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [title, setTitle] = useState("")

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		setTitle(props.title)
	}, [props.title])

	const appendContainer = (id, coins, hasCard, cardValue, cardColor, oldCardValue, stockId) => {
		let directCostCoins = []
		let stockCoins = []

		const diffCardValue = oldCardValue - cardValue;
		if (diffCardValue > 0) {

			if (coins.length > 0) {
				let totalCoins = coins.reduce((total, num) => {
					return parseFloat(total) + parseFloat(num);
				})

				const productValue = parseInt(totalCoins) / parseInt(oldCardValue);
				let directCostValue = productValue * cardValue;

				while (directCostValue > 0) {
					if (directCostValue >= 10) {
						directCostCoins.push(10)
						directCostValue -= 10
					}else if(directCostValue >= 1) {
						directCostCoins.push(1)
						directCostValue -= 1
					}else{
						directCostCoins.push(0.5)
						directCostValue -= 0.5
					}
				}

				let salesCostValue = productValue * diffCardValue;
				while (salesCostValue > 0) {
					if (salesCostValue >= 10) {
						stockCoins.push(10)
						salesCostValue -= 10
					}else if(salesCostValue >= 1) {
						stockCoins.push(1)
						salesCostValue -= 1
					}else{
						stockCoins.push(0.5)
						salesCostValue -= 0.5
					}
				}

				props.addContainerDirectCost("DirectCost", id, directCostCoins, hasCard, cardValue, cardColor, window.playerId, window.playerName);
				props.addContainerColorField('ColorField', cardColor, stockId, stockCoins, hasCard, diffCardValue, cardColor, window.playerId, window.playerName);

				// Update Socket
				RealtimeConnection.addContainer("DirectCost", {
					id: id,
					coins: directCostCoins,
					hasCard: hasCard,
					cardValue: cardValue,
					cardColor: cardColor
				})
				RealtimeConnection.addContainer("ColorField", {
					fieldType: cardColor,
					id: stockId,
					coins: stockCoins,
					hasCard: hasCard,
					cardValue: diffCardValue,
					cardColor: cardColor
				})
			}

		}else {
			directCostCoins = coins
			props.addContainerDirectCost("DirectCost", id, directCostCoins, hasCard, cardValue, cardColor, window.playerId, window.playerName);
			props.removeContainerColorField('ColorField', cardColor, stockId);

			RealtimeConnection.addContainer("DirectCost", {
				id: id,
				coins: directCostCoins,
				hasCard: hasCard,
				cardValue: cardValue,
				cardColor: cardColor
			})
			RealtimeConnection.removeContainer("ColorField", {
				color: cardColor,
				id: stockId,
				isDropToDelete: false
			})
		}

	}

	const removeContainer = (id) => {
		props.removeContainer("DirectCost", id);
	}

	const showContainerProperties = (id) => {
		props.openContainerProperties('FIELD', 'DirectCost', id);
	}

	return (
		<div className="Right-block left-right-block bottom">
			<Row>
				<Col>
					<div className="Text-bold-size">{ title }</div>
				</Col>
			</Row>
			<Row>
				{
					props.directCost.map((value, key) => {
						return (
							<React.Fragment key={key}>
								{
									(key % 3 === 0) ?
										<div className="w-100"></div>
										:
										null
								}
								<BottomRightBlockRowField
									canEdit={canEdit}
									id={key}
									object={value}
									appendContainer={appendContainer}
									removeContainer={removeContainer}
									showContainerProperties={showContainerProperties}
								/>
							</React.Fragment>
						)
					})
				}
			</Row>
			<Image src={rightArrow} className="Arrow-class" style={{"top": "30%"}} />
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		directCost: state.board.DirectCost
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomRightBlock);