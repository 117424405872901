import { combineReducers } from 'redux'

import simflexReducer from './simflex-reducer'
import propertiesReducer from './simflex-property-reducer'
import startingKit from "./starting-kit-reducer";
import sessionReducer from "./simflex-session-reducer";
import formReducer from "./forms-reducer";

export default combineReducers({
	board: simflexReducer,
	properties: propertiesReducer,
	startingKit: startingKit,
	session: sessionReducer,
	form: formReducer
})