import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";

import ColoredDroppableHorizontalField from "./Fields/ColoredDroppableHorizontalField";

const StockRedBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	const droppableFieldsList = props.data.map((value, key) => {
		if (key % 4 === 0) {
			return <React.Fragment key={key}>
				<div className="w-100"></div>
				<Col><ColoredDroppableHorizontalField canEdit={canEdit} id={key} type="luxus" fieldObject={value} /></Col>
			</React.Fragment>
		}

		return <Col key={key}><ColoredDroppableHorizontalField canEdit={canEdit} id={key} type="luxus" fieldObject={value} /></Col>
	})

	return (
		<Container className="Stock-red-block colored-block">
			<Row>
				{ droppableFieldsList }
			</Row>
		</Container>
	)
}

export default StockRedBlock;
