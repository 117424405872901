import React from "react";
import { Image } from "react-bootstrap";
import { DragPreviewImage, useDrag } from 'react-dnd';

// Images
import goldCoinImg from "../../../assets/images/dollar_coin.png";
import silverCoinImg from "../../../assets/images/dollar_coin_silver.png";
import bronzeCoinImg from "../../../assets/images/dollar_coin_bronze.png";
import previewGoldCoinImg from "../../../assets/images/dollar_coin-preview.png";
import previewSilverCoinImg from "../../../assets/images/dollar_coin_silver-preview.png";
import previewBronzeCoinImg from "../../../assets/images/dollar_coin_bronze-preview.png";

export default function MainDraggableCoin(props) {

	const [, drag, preview] = useDrag({
		item: { type: 'Coin', value: props.value, count: props.count },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let droppedItem = monitor.getDropResult();
			if (droppedItem !== null && droppedItem.type !== undefined) {
				if (monitor.didDrop() && dropResult.count >= dropResult.value) {
					props.decreaseStarterKit("coins", dropResult.value);
				}
			}
		}
	});

	let previewImg = previewGoldCoinImg;
	let coinImg = goldCoinImg;

	switch (props.value) {
		case 10:
			previewImg = previewGoldCoinImg;
			coinImg = goldCoinImg
			break
		case 1:
			previewImg = previewSilverCoinImg;
			coinImg = silverCoinImg
			break
		case 0.5:
			previewImg = previewBronzeCoinImg;
			coinImg = bronzeCoinImg
			break
		default:
			break
	}

	const canDrag = (props.canEdit) ? drag : null
	return (
		<>
			<DragPreviewImage connect={preview} src={previewImg} />
			<div className="draggable-coins-container" ref={canDrag}>
				<Image className="draggable-coins" src={coinImg} />
			</div>
		</>
	);

}