import React from 'react';
import { Table, FormControl } from 'react-bootstrap';

import Translate from "../../../../utils/Translate"

class IncomeStatement extends React.Component {

	handleChange(field, value) {
		this.props.inputChanged("incomeStatement", field, value)
	}

	render() {
		let grossProfit = 0
		grossProfit += (!isNaN(parseFloat(this.props.incomeStatement.salesRevenue))) ? parseFloat(this.props.incomeStatement.salesRevenue) : 0;
		grossProfit -= (!isNaN(parseFloat(this.props.incomeStatement.directCosts))) ? parseFloat(this.props.incomeStatement.directCosts) : 0;

		let totalOverhead = 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.indirectRAndDCosts))) ? parseFloat(this.props.incomeStatement.indirectRAndDCosts) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.docDevelopmentCosts))) ? parseFloat(this.props.incomeStatement.docDevelopmentCosts) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.productionPlants))) ? parseFloat(this.props.incomeStatement.productionPlants) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.machines))) ? parseFloat(this.props.incomeStatement.machines) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.indirectProductionCosts))) ? parseFloat(this.props.incomeStatement.indirectProductionCosts) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.marketing))) ? parseFloat(this.props.incomeStatement.marketing) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.storageCosts))) ? parseFloat(this.props.incomeStatement.storageCosts) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.indirectSalesCosts))) ? parseFloat(this.props.incomeStatement.indirectSalesCosts) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.processOptimization))) ? parseFloat(this.props.incomeStatement.processOptimization) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.qualityAssurance))) ? parseFloat(this.props.incomeStatement.qualityAssurance) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.lease))) ? parseFloat(this.props.incomeStatement.lease) : 0;
		totalOverhead += (!isNaN(parseFloat(this.props.incomeStatement.otherOverheads))) ? parseFloat(this.props.incomeStatement.otherOverheads) : 0;

		const ebit = grossProfit - totalOverhead;

		let profitBeforeTax = ebit;
		profitBeforeTax += (!isNaN(parseFloat(this.props.incomeStatement.otherIncomes))) ? parseFloat(this.props.incomeStatement.otherIncomes) : 0;
		profitBeforeTax -= (!isNaN(parseFloat(this.props.incomeStatement.interest))) ? parseFloat(this.props.incomeStatement.interest) : 0;

		//let lossCarriedForward = parseFloat(this.props.incomeStatement.lossCarriedForward);
		const taxes = (!isNaN(parseFloat(this.props.incomeStatement.taxes))) ? parseFloat(this.props.incomeStatement.taxes) : 0;
		/*if (lossCarriedForward >= profitBeforeTax) {
			taxes = 0
		}else{
			taxes = Math.round(((profitBeforeTax - lossCarriedForward) * 0.25) / .5) * .5;
		}*/

		const profitAfterTaxes = profitBeforeTax - taxes;

		return (
			<Table bordered>
				<tbody>
					<tr>
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Sales revenue" />
							</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.salesRevenue }
								onChange={ (e) => this.handleChange("salesRevenue", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Direct costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.directCosts }
								onChange={ (e) => this.handleChange("directCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Gross profit" /></div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ grossProfit } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Indirect R&D costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.indirectRAndDCosts }
								onChange={ (e) => this.handleChange("indirectRAndDCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Depreciation of capitalized development costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.docDevelopmentCosts }
								onChange={ (e) => this.handleChange("docDevelopmentCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Depreciation of production plants" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.productionPlants }
								onChange={ (e) => this.handleChange("productionPlants", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Depreciation of machines" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.machines }
								onChange={ (e) => this.handleChange("machines", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Indirect production costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.indirectProductionCosts }
								onChange={ (e) => this.handleChange("indirectProductionCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Marketing" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.marketing }
								onChange={ (e) => this.handleChange("marketing", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Storage costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.storageCosts }
								onChange={ (e) => this.handleChange("storageCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Indirect sales costs" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.indirectSalesCosts }
								onChange={ (e) => this.handleChange("indirectSalesCosts", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Process optimization" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.processOptimization }
								onChange={ (e) => this.handleChange("processOptimization", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Quality assurance" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.qualityAssurance }
								onChange={ (e) => this.handleChange("qualityAssurance", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Lease" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.lease }
								onChange={ (e) => this.handleChange("lease", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Other overheads" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.otherOverheads }
								onChange={ (e) => this.handleChange("otherOverheads", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Total overheads" /></div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ totalOverhead } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Operating profit / EBIT" /></div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ ebit } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Other incomes" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.otherIncomes }
								onChange={ (e) => this.handleChange("otherIncomes", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Interest" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.interest }
								onChange={ (e) => this.handleChange("interest", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold"><Translate alias="Forms" word="Profit before tax" /></div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ profitBeforeTax } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Loss carried forward" /></div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.lossCarriedForward }
								onChange={ (e) => this.handleChange("lossCarriedForward", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold"><Translate alias="Forms" word="Taxes" /> 25%</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.taxes } 
								onChange={ (e) => this.handleChange("taxes", e.target.value )} 
								disabled={ !this.props.status }
							/>
						</td>
					</tr>
					<tr className="autofillFieldBackground">
						<td>
							<div className="bold">
								<Translate alias="Forms" word="Profit after tax" />
							</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ profitAfterTaxes } disabled />
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}

}

export default IncomeStatement;