import React, { Component } from 'react';
import {Image} from "react-bootstrap";
import logo from "./assets/images/header-logo.png";


class HomeComponent extends Component {

	componentDidMount() {
		let htmlElement = document.getElementsByTagName("html");
		if (htmlElement.length > 0) {
			htmlElement[0].style.height = "100%"
		}

		let bodyElement = document.getElementsByTagName("body");
		if (bodyElement.length > 0) {
			bodyElement[0].style.height = "100%"
		}

		let rootElement = document.getElementById("root");
		rootElement.style.height = "100%";
	}

	render() {
		console.log(this.props);
		return (
			<div className="form-body">
				<div className="row">
					<div className="img-holder">
						<div className="bg"></div>
						<div className="info-holder"></div>
					</div>
					<div className="form-holder">
						<div className="form-content">
							<div className="form-items">
								<div className="form-items">
									<div className="website-logo-inside">
										<a href="./">
											<div className="logo">
												<Image className="logo-size" src={logo} alt="" />
											</div>
										</a>
									</div>
									<h3>
										Welcome to Simdustry Drag & Drop Platform..
									</h3>
									<p>
										{
											(this.props.isLoggedOut) ?
												"You Logged out successfully"
												:
												`${ this.props.notAuthorizedMessage }`
										}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

}

export default HomeComponent;

/*
<div className="NotAuthorizedContainer">
	<div className="header-logo-container-2">
		<Image src={logo} className="App-logo" />
	</div>
	<div className="NotAuthorizedText">
		{
			(this.props.isLoggedOut) ?
				"You Logged out successfully"
				:
				`${ this.props.notAuthorizedMessage }`
		}
	</div>
</div>
*/