import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {connect} from "react-redux";

import Translate from "../../utils/Translate"

// Dispatcher
import mapDispatchToProps from "../../reducers/dispatchers";

// Components
import LeftBlock from "./LeftBlock/LeftBlock";
import LeftBlockSpacer from "./LeftBlock/LeftBlockSpacer";
import CenterBlock from "./CenterBlock/CenterBlock";
import BottomBlock from "./CenterBlock/BottomBlock/BottomBlock";
import RightBlock from "./RightBlock/RightBlock";
import RightBlockSpacer from "./RightBlock/RightBlockSpacer";
import BottomRightBlock from "./RightBlock/BottomRightBlock";
import LoadCSS from './LoadCSS';

// CSS
import '../../css/SimFlexMAN/App.css';
import '../../css/SimFlexMAN/Style3dModel.css';
import '../../css/SimFlexMAN/Zoom.css';
import '../../css/PropertiesPanel.css';

import "../../css/SimFlexMAN/Common.css";
import "../../css/SimFlexMAN/LeftBlock.css";
import '../../css/SimFlexMAN/RightBlock.css';
import "../../css/SimFlexMAN/BottomBlock.css";
import "../../css/SimFlexMAN/FinanceBlock.css";
import "../../css/SimFlexMAN/ResearchBlock.css";
import "../../css/SimFlexMAN/PurchaseBlock.css";
import "../../css/SimFlexMAN/ProductionBlock.css";
import "../../css/SimFlexMAN/SalesStockBlock.css";

import ManLogo from '../../assets/images/manLogo.svg'

let scrollIntervalTime = undefined

const SimFlexMAN = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	const updateDrageMousePosition = ev => {
		//console.log(ev)
		if (ev.clientX >= (window.innerWidth - 100)) {
			if (!scrollIntervalTime) {
				scrollIntervalTime = setInterval( () => {
					scrollRightAction()
				}, 50)
			}
		}else if (ev.clientX <= 100) {
			if (!scrollIntervalTime) {
				scrollIntervalTime = setInterval( () => {
					scrollLeftAction()
				}, 50)
			}
		}else{
			if (scrollIntervalTime) {
				clearInterval(scrollIntervalTime)
				scrollIntervalTime = undefined
			}
		}
	}

	useEffect(() => {
		window.addEventListener("dragover", updateDrageMousePosition);
		return () => window.removeEventListener("dragover", updateDrageMousePosition);
	}, [])

	const scrollRightAction = () => {
		window.scrollBy({
			left: 50,
			behavior: 'smooth'
		})

		if ((document.body.scrollWidth - window.innerWidth) === (window.pageXOffset - (window.innerWidth - document.body.offsetWidth)) ) {
			if (scrollIntervalTime) {
				clearInterval(scrollIntervalTime)
				scrollIntervalTime = undefined
			}
		}
	}

	const scrollLeftAction = () => {
		window.scrollBy({
			left: -50,
			behavior: 'smooth'
		})

		if ( window.pageXOffset === 0 ) {
			if (scrollIntervalTime) {
				clearInterval(scrollIntervalTime)
				scrollIntervalTime = undefined
			}
		}
	}

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	return (
		<div className="App">
			<Container fluid>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
					<img src={ManLogo} style={{ width: 70 }} />
				</div>
				<Row className="Board">
					<div className="Left-column">
						<LeftBlock canEdit={canEdit} id={0} title={ <Translate alias="Board" word="Taxes" /> } />
						<LeftBlock canEdit={canEdit} id={1} title={ <Translate alias="Board" word="Interests" /> } />
						<LeftBlock canEdit={canEdit} id={2} title={ <Translate alias="Board" word="Other Overheads" /> } />
						<LeftBlockSpacer />
						<LeftBlock canEdit={canEdit} id={3} title={ <Translate alias="Board" word="Lease" /> } />
						<LeftBlock canEdit={canEdit} id={4} title={ <Translate alias="Board" word="Quality assurance" /> } />
						<LeftBlock canEdit={canEdit} id={5} title={ <Translate alias="Board" word="Process optimization" /> } />
						<LeftBlockSpacer />
						<LeftBlockSpacer />
						<LeftBlock canEdit={canEdit} id={6} title={ <Translate alias="Board" word="Indirect sales costs" />} />
					</div>
					<div className="Middle-column">
						<CenterBlock canEdit={canEdit} />
						<div>
							<Row>
								<Col>
									<BottomBlock canEdit={canEdit} id={0} isFirst={true} title={ <Translate alias="Board" word="Other incomes" /> } />
								</Col>
								<Col></Col>
								<Col>
									<BottomBlock canEdit={canEdit} id={1} title={ <Translate alias="Board" word="Storage costs" /> } />
								</Col>
								<Col>
									<BottomBlock canEdit={canEdit} id={2} title={ <Translate alias="Board" word="Marketing" /> } />
								</Col>
							</Row>
						</div>
					</div>
					<div className="Right-column">
						<RightBlock canEdit={canEdit} id={0} title={ <Translate alias="Board" word="Indirect R & D costs" /> } />
						<RightBlock canEdit={canEdit} id={1} title={ <Translate alias="Board" word="Depreciation of capitalized development costs" /> } />
						<RightBlockSpacer />
						<RightBlockSpacer />
						<RightBlock canEdit={canEdit} id={2} title={ <Translate alias="Board" word="Depreciation of production plants" /> } />
						<RightBlock canEdit={canEdit} id={3} title={ <Translate alias="Board" word="Depreciation of machines" /> } />
						<RightBlock canEdit={canEdit} id={4} title={ <Translate alias="Board" word="Indirect production costs" /> } />
						<RightBlockSpacer />
						<BottomRightBlock canEdit={canEdit} title={ <Translate alias="Board" word="Direct costs" /> } />
					</div>
				</Row>
			</Container>
		</div>
	);

}

const mapStateToProps = (state) => {
	return {
		session: state.session
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SimFlexMAN);