import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Image } from 'react-bootstrap';

// Constants
import dropFieldClassNames from "../../../utils/dropFieldClassNames";

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";
import * as RealtimeConnection from "../RealtimeConnection";

// Images
import rightArrow from "../../../assets/images/right-arrow.svg";

// Components
import DroppableField from "../Common/DroppableField";

const RightBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [id, setId] = useState(0)
	const [title, setTitle] = useState("")

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		setId(props.id)
	}, [props.id])

	useEffect(() => {
		setTitle(props.title)
	}, [props.title])

	const appendContainer = (coins) => {
		props.addContainer("RightBlock", id, coins, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addContainer("RightBlock", {
			id: id,
			coins: coins
		})
	}

	const removeContainer = (isDropToDelete) => {
		props.removeContainer("RightBlock", id);

		// Update Socket
		RealtimeConnection.removeContainer("RightBlock", {
			id: id,
			isDropToDelete: isDropToDelete
		})
	}

	const addCoin = (value) => {
		props.addCoin("RightBlock", id, value, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addCoin("RightBlock", {
			id: id,
			value: value
		})
	}

	const removeCoin = (index, isDropToDelete) => {
		props.removeCoin("RightBlock", id, index, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.removeCoin("RightBlock", {
			id: id,
			index: index,
			isDropToDelete: isDropToDelete
		})
	}


	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "RightBlock",
			isDragging: isDragging,
			type: type,
			id: id
		}
		dataObj = { ...data, ...dataObj }
		window.socket.emit('item-dragging', dataObj);
	}

	const showContainerProperties = () => {
		props.openContainerProperties('FIELD', 'RightBlock', id);
	}

	return (
		<Container>
			<Row>
				<div className="left-right-block Right-block">
					<Row>
						<div className="text-column">
							<p className="Text-bold-size">{ title }</p>
						</div>
						<div className="field-column" onClick={showContainerProperties}>
							<DroppableField
								canEdit={canEdit}
								class={ dropFieldClassNames.RightBlock }
								data={ props.rightBlock[id] }
								isHorizontal={ false }
								appendContainer={ appendContainer }
								removeContainer={ removeContainer }
								addCoin={ addCoin }
								removeCoin={ removeCoin }
								isDraggingHandler={ isDraggingHandler }
							/>
						</div>
					</Row>
					<Image src={ rightArrow } className="Arrow-class" />
				</div>
			</Row>
		</Container>
	)
}

const mapStateToProps = (state) => {
	return {
		rightBlock: state.board.RightBlock
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RightBlock);