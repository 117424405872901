import React from 'react';
import {connect} from "react-redux";
import { Container, Row, Col, Table, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

import Translate from '../../../utils/Translate'

import { FaCheck } from 'react-icons/fa';

import "../../../css/Forms.css";

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";

import * as RealtimeConnection from "../RealtimeConnection";

// Components
import FixedAsset from "./Partials/StrategyPaperFixedAsset";
import StrategyPaperFormControl from './Partials/StrategyPaperFormControl';
import StrategyPaperProduction from './Partials/StrategyPaperProduction';

class StrategyPaper extends React.Component {

	closeForm = () => {
		this.props.openFormView("")
	}

	checkboxPressed = (section, field) => {
		if (this.props.canEdit) {
			if (this.props.session.formStatus.strategyPaper) {
				if (this.props.session.year === this.props.session.formSelectedYear) {
					if (this.props.strategyPaper[section][field]) {
						this.props.updateFormOption("StrategyPaper", section, field, undefined, false)
			
						// Update Socket
						RealtimeConnection.updateForm({
							year: this.props.session.year,
							type: "StrategyPaper",
							section: section,
							field: field,
							value: false
						})
					}else{
						this.props.updateFormOption("StrategyPaper", section, field, undefined, true)
			
						// Update Socket
						RealtimeConnection.updateForm({
							year: this.props.session.year,
							type: "StrategyPaper",
							section: section,
							field: field,
							value: true
						})
					}
				}
			}
		}
	}

	inputChanged = (field, value) => {
		this.props.updateFormOption("StrategyPaper", "credits", field, undefined, value)

		// Update Socket
		RealtimeConnection.updateForm({
			year: this.props.session.year,
			type: "StrategyPaper",
			section: "credits",
			field: field,
			value: value
		})
	}

	optionChanged = (section, field, option, value) => {
		this.props.updateFormOption("StrategyPaper", section, field, option, value)

		// Update Socket
		RealtimeConnection.updateForm({
			year: this.props.session.year,
			type: "StrategyPaper",
			section: section,
			field: field,
			option: option,
			value: value
		})
	}

	changeFormYear = (selectedYear) => {
		this.props.changeFormYear(selectedYear);

		RealtimeConnection.formChangeYear({
			year: selectedYear,
			type: "StrategyPaper"
		})
	}

	render() {
		const selectedLang = this.props.session.selectedLang;

		let yearsOptions = [];
		for(let i = 0; i <= this.props.session.year; i++) {
			yearsOptions.push(<Dropdown.Item key={i} eventKey={i} onClick={ this.changeFormYear.bind(this, i) }>Year { i }</Dropdown.Item>)
		}

		let isCurrentYear = false;
		if (this.props.session.year === this.props.session.formSelectedYear) {
			isCurrentYear = true
		}

		if (!this.props.canEdit) {
			isCurrentYear = false
		}

		return (
			<Container className="formContainer">
				<Row>
					<Col>
						<div className="formHeader">
							<div className="headerTitle bold">
								<Translate alias="Forms" word="Strategy Paper" />
								<DropdownButton
									as={ButtonGroup}
									variant="outline-secondary"
									title={ `Year ${this.props.session.formSelectedYear}` }
									size="sm"
									style={{ marginLeft: "10px" }}
								>
									{ yearsOptions }
								</DropdownButton>
							</div>
							<div className="closeForm" onClick={ this.closeForm.bind(this) }>X</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table bordered>
							<tbody>
								<tr>
									<td style={{ width: "100px" }}>
										<div className="bold">
											<Translate alias="Forms" word="Development of products" />
										</div>
									</td>
									<td>
										<Container>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">
														<Translate alias="Forms" word="Compact" />
													</div>
													<div className="hintForm">2 <Translate alias="Forms" word="years" /> <Translate alias="Forms" word="costs" /> 6 (3/3)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "developmentOfProducts", "compact")}>
														{
															(this.props.strategyPaper.developmentOfProducts.compact) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">
														<Translate alias="Forms" word="Plus" />
													</div>
													<div className="hintForm">3 <Translate alias="Forms" word="years" /> <Translate alias="Forms" word="costs" /> 8 (3/3/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "developmentOfProducts", "plus")}>
														{
															(this.props.strategyPaper.developmentOfProducts.plus) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold"><Translate alias="Forms" word="Luxus" /></div>
													<div className="hintForm">5 <Translate alias="Forms" word="years" /> <Translate alias="Forms" word="costs" /> 10 (2/2/2/2/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "developmentOfProducts", "luxus")}>
														{
															(this.props.strategyPaper.developmentOfProducts.luxus) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
										</Container>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">
											<Translate alias="Forms" word="Opening-up of markets" />
										</div>
									</td>
									<td>
										<Container>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">
														<Translate alias="Forms" word="International" />
													</div>
													<div className="hintForm">1 <Translate alias="Forms" word="year" /> <Translate alias="Forms" word="costs" /> 2 (2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "openingMarket", "international") }>
														{
															(this.props.strategyPaper.openingMarket.international) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold"><Translate alias="Forms" word="Intercontinental 1" /></div>
													<div className="hintForm">2 <Translate alias="Forms" word="years" /> <Translate alias="Forms" word="costs" /> 4 (2/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "openingMarket", "intercontinental1") }>
														{
															(this.props.strategyPaper.openingMarket.intercontinental1) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold"><Translate alias="Forms" word="Intercontinental 2" /></div>
													<div className="hintForm">4 <Translate alias="Forms" word="years" /> <Translate alias="Forms" word="costs" /> 8 (2/2/2/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "openingMarket", "intercontinental2") }>
														{
															(this.props.strategyPaper.openingMarket.intercontinental2) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
										</Container>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold"><Translate alias="Forms" word="Fixed assets" /></div>
										<div className="hintForm">- <Translate alias="Forms" word="Prod. facilities" />?</div>
										<div className="hintForm">- <Translate alias="Forms" word="Purchase" />/<Translate alias="Forms" word="Rent" />?</div>
										<div className="hintForm">- <Translate alias="Forms" word="Machines" />?</div>
										<div className="hintForm">&nbsp; <span className="capitalizeLetter"><Translate alias="Forms" word="f" /></span> - <Translate alias="Forms" word="Fast" /> 10</div>
										<div className="hintForm">&nbsp; <span className="capitalizeLetter"><Translate alias="Forms" word="m" /></span> - <Translate alias="Forms" word="Medium" /> 7.5</div>
										<div className="hintForm">&nbsp; <span className="capitalizeLetter"><Translate alias="Forms" word="s" /></span> - <Translate alias="Forms" word="Slow" /> 5</div>
									</td>
									<td>
										<Container>
											<FixedAsset
												option="national_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.national_1 } 
												index={ 0 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="national_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.national_2 } 
												index={ 1 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="national_3"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.national_3 } 
												index={ 2 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="international_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.international_1 } 
												index={ 3 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="international_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.international_2 } 
												index={ 4 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="international_3"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.international_3 } 
												index={ 5 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="intercontinental1_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental1_1 } 
												index={ 6 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="intercontinental1_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental1_2 } 
												index={ 7 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="intercontinental2_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental2_1 } 
												index={ 8 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
											<FixedAsset 
												option="intercontinental2_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental2_2 } 
												index={ 9 } 
												selectedLang={ selectedLang }
												status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
											/>
										</Container>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold"><Translate alias="Forms" word="Production in Prod. facility" /></div>
										<div className="hintForm">- <Translate alias="Forms" word="Product" />?</div>
										<div className="hintForm">- <Translate alias="Forms" word="Quantity" />?</div>
									</td>
									<td>
										<StrategyPaperProduction
											option="national_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.national_1 } 
											index={ 0 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="national_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.national_2 } 
											index={ 1 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="national_3"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.national_3 } 
											index={ 2 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="international_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.international_1 } 
											index={ 3 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="international_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.international_2 } 
											index={ 4 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="international_3"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.international_3 } 
											index={ 5 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="intercontinental1_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental1_1 } 
											index={ 6 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="intercontinental1_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental1_2 } 
											index={ 7 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="intercontinental2_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental2_1 } 
											index={ 8 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
										<StrategyPaperProduction 
											option="intercontinental2_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental2_2 } 
											index={ 9 } 
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
										/>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">
											<Translate alias="Forms" word="Credit take-up" />
										</div>
									</td>
									<td>
										<Container>
											<Row>
												<Col>
													<div className="bold"><Translate alias="Forms" word="Credit status 5%" /></div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "nonCurrent" }
														value={ this.props.strategyPaper.credits.nonCurrent }
														status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="bold"><Translate alias="Forms" word="Credit status 10%" /></div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "current" }
														value={ this.props.strategyPaper.credits.current }
														status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="bold"><Translate alias="Forms" word="Credit take-up/repayment" /></div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "takeUpCredit" }
														value={ this.props.strategyPaper.credits.takeUpCredit }
														status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="bold"><Translate alias="Forms" word="New credit status" /></div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "creditStatus" }
														value={ this.props.strategyPaper.credits.creditStatus }
														status={ this.props.session.formStatus.strategyPaper && isCurrentYear }
													/>
												</Col>
											</Row>
										</Container>
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		strategyPaper: state.form.StrategyPaper
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(StrategyPaper);

//value={ this.props.strategyPaper.credits.nonCurrent }