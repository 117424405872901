import React from 'react';
import {connect} from "react-redux";
import { Container, Row, Col, Table, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

import Translate from '../../../utils/Translate'

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";
import * as RealtimeConnection from "../RealtimeConnection";

import "../../../css/Forms.css";

import DecisionSheetFields from "./Partials/DecisionSheetFields";

class DecisionSheet extends React.Component {

	closeForm = () => {
		this.props.openFormView("")
	}

	inputChanged = (section, field, option, value) => {
		this.props.updateFormOption("DecisionSheet", section, field, option, value)

		// Update Socket
		RealtimeConnection.updateForm({
			year: this.props.session.year,
			type: "DecisionSheet",
			section: section,
			field: field,
			option: option,
			value: value
		})
	}

	changeFormYear = (selectedYear) => {
		this.props.changeFormYear(selectedYear);

		RealtimeConnection.formChangeYear({
			year: selectedYear,
			type: "DecisionSheet"
		})
	}

	render() {
		const selectedLang = this.props.session.selectedLang;

		let yearsOptions = [];
		for(let i = 0; i <= this.props.session.year; i++) {
			yearsOptions.push(<Dropdown.Item key={i} eventKey={i} onClick={ this.changeFormYear.bind(this, i) }>Year { i }</Dropdown.Item>)
		}

		let isCurrentYear = false;
		if (this.props.session.year === this.props.session.formSelectedYear) {
			isCurrentYear = true
		}

		if (!this.props.canEdit) {
			isCurrentYear = false
		}

		return (
			<Container className="formContainer">
				<Row>
					<Col>
						<div className="formHeader">
							<div className="headerTitle bold">
								<Translate alias="Forms" word="Decision Sheet" />
								<DropdownButton
									as={ButtonGroup}
									variant="outline-secondary"
									title={ `Year ${this.props.session.formSelectedYear}` }
									size="sm"
									style={{ marginLeft: "10px" }}
								>
									{ yearsOptions }
								</DropdownButton>
							</div>
							<div className="closeForm" onClick={ this.closeForm.bind(this) }>X</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table bordered>
							<thead>
								<tr>
									<th style={{ width: "120px" }}>
										<div className="bold">
											<Translate alias="Forms" word="Market" />
										</div>
									</th>
									<th>
										<div className="bold">
											<Translate alias="Forms" word="Products" />
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<div className="bold">
											<Translate alias="Forms" word="National" />
										</div>
									</td>
									<td>
										<DecisionSheetFields
											section="national"
											inputChanged={this.inputChanged}
											field={ this.props.decisionSheet.national }
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.decisionSheet && isCurrentYear }
										/>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">
											<Translate alias="Forms" word="International" />
										</div>
									</td>
									<td>
										<DecisionSheetFields 
											section="international"
											inputChanged={this.inputChanged}
											field={ this.props.decisionSheet.international }
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.decisionSheet && isCurrentYear }
										/>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">
											<Translate alias="Forms" word="Intercontenintal 1" />
										</div>
									</td>
									<td>
										<DecisionSheetFields 
											section="intercontinental1"
											inputChanged={this.inputChanged}
											field={ this.props.decisionSheet.intercontinental1 }
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.decisionSheet && isCurrentYear }
										/>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">
											<Translate alias="Forms" word="Intercontenintal 2" />
										</div>
									</td>
									<td>
										<DecisionSheetFields 
											section="intercontinental2"
											inputChanged={this.inputChanged}
											field={ this.props.decisionSheet.intercontinental2 }
											selectedLang={ selectedLang }
											status={ this.props.session.formStatus.decisionSheet && isCurrentYear }
										/>
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		decisionSheet: state.form.DecisionSheet
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DecisionSheet);