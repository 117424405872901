import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";

import Translate from "../../../utils/Translate"

// Components
import SalesBlock from "./SalesBlock/SalesBlock";
import StockGreenBlock from "./StockBlock/StockGreenBlock";
import StockYellowBlock from "./StockBlock/StockYellowBlock";
import StockRedBlock from "./StockBlock/StockRedBlock";
import StockBlueBlock from "./StockBlock/StockBlueBlock";

const SalesStockBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	return (
		<div className="Sales-Stock-block">
			<Row>
				<Col xs={1}>
					<div className="Sales-title bold-text">
						<Translate alias="Board" word="Sales" />
					</div>
				</Col>
				<Col>
					<SalesBlock canEdit={canEdit} />
				</Col>
				<Col xs={1} style={{ "marginLeft": "5px" }}>
					<div className="Sales-title bold-text">
						<Translate alias="Board" word="Stock" />
					</div>
				</Col>
				<Col xs={4} style={{ "marginRight": "5px" }}>
					<StockGreenBlock canEdit={canEdit} data={props.colorBlocks.standard} />
					<StockYellowBlock canEdit={canEdit} data={props.colorBlocks.compact} />
				</Col>
				<Col xs={4}>
					<StockBlueBlock canEdit={canEdit} data={props.colorBlocks.plus} />
					<StockRedBlock canEdit={canEdit} data={props.colorBlocks.luxus} />
				</Col>
			</Row>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		colorBlocks: state.board.CenterBlock.Stock
	}
}

export default connect(mapStateToProps)(SalesStockBlock);
