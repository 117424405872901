import React, { useEffect, useState} from "react";
import { connect } from "react-redux";


// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";
import * as RealtimeConnection from "../../RealtimeConnection";

// Components
import TokenDroppableField from "./TokenDroppableField";

const TokenDroppableContainer = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	const appendToken = (value, type) => {
		props.addToken(props.type, value, type);

		// Update Socket
		RealtimeConnection.addToken({
			type: props.type,
			value: value,
			tokenType: type
		})
	}

	const removeToken = (tokenValue) => {
		props.removeToken(props.type, tokenValue)

		// Update Socket
		RealtimeConnection.removeToken({
			type: props.type,
			value: tokenValue
		})
	}

	return (
		<TokenDroppableField
			canEdit={canEdit}
			type={props.type}
			value={props.value}
			appendToken={appendToken}
			removeToken={removeToken}
		/>
	);
}

export default connect(null, mapDispatchToProps)(TokenDroppableContainer);