import React from "react";
import {useDrop} from 'react-dnd';
import {Image} from "react-bootstrap";

// Constants
import items from "../../../utils/items";

// Images
import deleteImg from "../../../assets/images/delete.png";
import deleteActiveImg from "../../../assets/images/delete-active.png";

export default function MainDraggableDelete(props) {

	const [{ isOver, canDrop }, drop] = useDrop({
		accept: [
			"DroppedToken",
			items.container,
			items.factoryContainer,
			items.machineContainer,
			items.productionContainer,
			items.coin,
			items.pin,
			items.productLbl,
			items.factoryLbl,
			items.machineLbl
		],
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			if (item.type === "DroppedToken") {
				props.increaseStarterKit("tokens", item.value.value)
			}else if (item.type === items.pin) {
				props.increaseStarterKit("pins", 1);
			}else if(item.type === items.coin) {
				props.increaseStarterKit("coins", item.value)
			}else if(item.type === items.container) {

				if (item.coins.length > 0) {
					let sum = item.coins.reduce(function(a, b){
						return a + b;
					}, 0);
					props.increaseStarterKit("coins", sum)
				}
			}else if(item.type === items.factoryContainer) {
				if (item.coins.length > 0) {
					let sum = item.coins.reduce(function(a, b){
						return a + b;
					}, 0);
					props.increaseStarterKit("coins", sum)
				}

				if (item.hasCard === true) {
					props.increaseStarterKit("factory", 1)
				}
			}else if(item.type === items.machineContainer) {
				if (item.coins.length > 0) {
					let sum = item.coins.reduce(function(a, b){
						return a + b;
					}, 0);
					props.increaseStarterKit("coins", sum)
				}

				if (item.hasCard === true) {
					props.increaseStarterKit("machine", 1)
				}
			}else if(item.type === items.productionContainer) {
				if (item.coins.length > 0) {
					let sum = item.coins.reduce(function(a, b){
						return a + b;
					}, 0);
					props.increaseStarterKit("coins", sum)
				}

				if (item.hasCard === true) {
					props.increaseStarterKit("production", item.cardValue, item.cardColor)
				}
			}

			return { ...item, droppableType: 'delete' }
		},
	})

	let img = (isOver && canDrop) ? deleteActiveImg : deleteImg;

	return (
		<div className="removeDroppable" ref={drop}>
			<Image src={img} />
		</div>
	);
}