import React, { Component } from "react";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import axios from 'axios';

import Translate from "../../utils/Translate"

// Images
import documentIcon from "../../assets/images/document-icon.png";
import checkListIcon from "../../assets/images/checklist-icon.png";

// Dispatchers
import mapDispatchToProps from "../../reducers/dispatchers";

// Components
import DecisionSheet from "./Forms/DecisionSheet";
import StrategyPaper from "./Forms/StrategyPaper";
import AnnualReport from "./Forms/AnnualReport";

import URLs from "../../utils/urls";

class FilesLibrary extends Component{

	constructor(props) {
		super(props);

		this.state = {
			isMenuOpened: false,
			isCheckListOpened: false,
			checklistData: []
		}
	}

	componentDidMount() {
		this.fetchChecklist()
	}

	fetchChecklist = async () => {
		const result = await axios.post(URLs.checklist, {
			languageId: (localStorage.langId) ? localStorage.langId : 3,
			sessionId: window.sessionId
		}, { headers: {"content-type": "application/json"} })

		if (result.data.data) {
			console.log("HEREEE")
			console.log(result.data.data);
			this.setState({ checklistData: result.data.data })
		}
	}

	toggleFilesMenu = () => {
		if (this.state.isMenuOpened) {
			this.setState({ isMenuOpened: false });
			this.updateUI("FILES", "close");
		}else{
			this.setState({ isMenuOpened: true });
			this.setState({ isCheckListOpened: false });
			this.updateUI("FILES", "open");
		}
	}

	toggleCheckListMenu = () => {
		if (this.state.isCheckListOpened) {
			this.setState({ isCheckListOpened: false });
			this.updateUI("CHECKLIST", "close");
		}else{
			this.setState({ isCheckListOpened: true });
			this.setState({ isMenuOpened: false });
			this.updateUI("CHECKLIST", "open");
		}
	}

	updateUI = (panelType, status) => {
		const appParentContainer = document.getElementsByClassName("main-app-bg");
		const appContainer = document.getElementsByClassName("App");
		const filesContainer = document.getElementsByClassName("filesContainer");
		const checkListContainer = document.getElementsByClassName("checkListContainer");

		if (status === "close") {
			appParentContainer[0].classList.remove("width");
			appContainer[0].classList.remove('moveRight');
			filesContainer[0].classList.remove('fullWidth');
			checkListContainer[0].classList.remove('fullWidth');
		}else{
			if (panelType === "FILES") {
				const menuContainer = document.getElementsByClassName("kitContainer");
				appContainer[0].classList.remove('moveLeft');
				menuContainer[0].classList.remove('fullWidth');

				appParentContainer[0].classList.add("width");
				appContainer[0].classList.add('moveRight');
				filesContainer[0].classList.add('fullWidth');
				checkListContainer[0].classList.remove('fullWidth');
			}else if (panelType === "CHECKLIST") {
				checkListContainer[0].classList.add('fullWidth');
				filesContainer[0].classList.remove('fullWidth');
			}else{
				this.setState({ isMenuOpened: false, isCheckListOpened: false }, () => {
					filesContainer[0].classList.remove('fullWidth');
					checkListContainer[0].classList.remove('fullWidth');
				});
			}
		}
	}

	openForm = (form) => {
		this.props.openFormView(form)
	}

	render() {
		let selectedLang = this.props.session.selectedLang;
		if (selectedLang === "ar" || selectedLang === "sp") {
			selectedLang = "en"
		}

		let files = [];
		let links = [];
		this.props.session.files.forEach(obj => {
			if (obj.type === "file") {
				files.push(obj);
			}else{
				links.push(obj);
			}
		})

		console.log(this.state.checklistData)
		return (
			<div className={this.props.boardType}>
				<div className={`files-library-btn`}>
					<div className="section" onClick={ this.toggleCheckListMenu }>
						<div className="button checklist">
							<Image src={checkListIcon} />
							<div className="text">
								<Translate alias="Board" word="Check List" />
							</div>
						</div>
					</div>
					<div className="section" onClick={ this.toggleFilesMenu }>
						<div className="button">
							<Image src={documentIcon} />
							<div className="text">
								<Translate alias="Board" word="Files Library" />
							</div>
						</div>
					</div>
				</div>
				<div className="checkListContainer">
					<div className="files-header">
						<div className="files-header-logo">
							<Image src={checkListIcon} />
							<div className="text">
								<Translate alias="Board" word="Check List" />
							</div>
						</div>
					</div>
					<div className="blur">
						{
							(this.state.checklistData.map( obj => {
								return (
									<div className="checkListRowContainer" key={ obj.id }>
										<div className="checkListTitle">
											{ obj.name }
										</div>
										{(
											obj.Checklist.map( row => {
												return (
													<div className="checkListRow" key={ row.id }>
														{
															(row.id === 11) ?
																<div className="phaseSeparator">
																	<div className="phaseText">
																		<i className="arrow up"></i> <Translate alias="Board" word="Phase" /> 1
																	</div>
																	<div className="phaseLineSeparator"></div>
																	<div className="phaseText">
																		<i className="arrow down"></i> <Translate alias="Board" word="Phase" /> 2
																	</div>
																</div>
																:
																null
														}
														<div className="checkListSubtitle">
															<div dangerouslySetInnerHTML={{ __html: row.title }} />
														</div>
														<div className="checkListValue">
															<div dangerouslySetInnerHTML={{ __html: row.subtitle }} />
														</div>
														<div style={{ fontSize: 12, marginBottom: "1rem" }}>
															<div dangerouslySetInnerHTML={{ __html: row.actions }} />
														</div>
													</div>
												)
											})
										)}
									</div>
								);
							}))
						}
					</div>
				</div>
				<div className="filesContainer">
					<div className="files-header">
						<div className="files-header-logo">
							<Image src={documentIcon} />
							<div className="text">
								<Translate alias="Board" word="Files Library" />
							</div>
						</div>
					</div>
					<div className="blur">
						{
							(this.props.session.openedForm !== "") ?
								<>
									{(
										(this.props.session.openedForm === "DecisionSheet" && <DecisionSheet canEdit={ this.props.canEdit } />)
										|| 
										(this.props.session.openedForm === "StrategyPaper" && <StrategyPaper canEdit={ this.props.canEdit } />)
										||
										(this.props.session.openedForm === "AnnualReport" && <AnnualReport canEdit={ this.props.canEdit } />)
									)}
								</>
							:
								<>
								<div className="files-section">
									<div className="filesListTitle">
										<Translate alias="Board" word="Forms" />
									</div>
									<div className="fileRow">
										<div className="file-link form-link">
											<div onClick={ this.openForm.bind(this, "DecisionSheet")} >
												<Translate alias="Forms" word="Decision Sheet" />
											</div>
										</div>
									</div>
									<div className="fileRow">
										<div className="file-link form-link">
											<div href="#" onClick={ this.openForm.bind(this, "StrategyPaper")} rel="noopener noreferrer">
												<Translate alias="Forms" word="Strategy Paper" />
											</div>
										</div>
									</div>
									<div className="fileRow">
										<div className="file-link form-link">
											<div href="#" onClick={ this.openForm.bind(this, "AnnualReport")} rel="noopener noreferrer">
												<Translate alias="Forms" word="Annual Report" />
											</div>
										</div>
									</div>
								</div>
								{
									(files.length > 0) ?
										<div className="files-section">
											<div className="filesListTitle">
												<Translate alias="Board" word="Files" />
											</div>
											{
												files.map((obj, index) => {
													return (
														<div className="fileRow" key={ index }>
															<div className="file-link">
																<a href={ `${ URLs.backendURL }/uploads/sessions/images/${ obj.file }` } target="_blank" rel="noopener noreferrer">{ obj.name }</a>
															</div>
														</div>
													);
												})
											}
										</div>
										:
										null
								}
								{
									(links.length > 0) ?
										<div className="links-section">
											<div className="linksListTitle">
												<Translate alias="Board" word="Links" />
											</div>
											{
												links.map((obj, index) => {
													return (
														<div className="fileRow" key={ index }>
															<div className="file-link">
																<a href={ `${ obj.file }` } target="_blank" rel="noopener noreferrer">{ obj.name }</a>
															</div>
														</div>
													);
												})
											}
										</div>
										:
										null
								}
							</>
						}
					</div>
				</div>
			</div>
		)
	}

}

const mapStateToProps = (state) => {
	return {
		session: state.session
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesLibrary);