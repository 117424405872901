import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";

// Constants
import dropFieldClassNames from "../../../../utils/dropFieldClassNames";

// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";
import * as RealtimeConnection from "../../RealtimeConnection";

// Components
import NumberBox from "../../Common/NumberBox";
import DroppableField from "../../Common/DroppableField";


const PurchaseRow = (props) => {

	const [canEdit, setCanEdit] = useState(false)
	const [className, setClassName] = useState("")
	const [rowKey, setRowKey] = useState("")

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		setClassName(props.className)
	}, [props.className])

	useEffect(() => {
		setRowKey(props.title.toLowerCase())
	}, [props.title])

	const appendContainer = (coins, id) => {
		props.addContainerFieldType('Purchase', rowKey, coins, id, false, 0, "", window.playerId, window.playerName)

		// Update Socket
		RealtimeConnection.addContainer("Purchase", {
			fieldType: rowKey,
			id: id,
			coins: coins
		})
	}

	const removeContainer = (id, isDropToDelete) => {
		props.removeContainerFieldType('Purchase', rowKey, id)

		// Update Socket
		RealtimeConnection.removeContainer("Purchase", {
			fieldType: rowKey,
			id: id,
			isDropToDelete: isDropToDelete
		})
	}

	const addCoin = (id, value) => {
		props.addCoinFieldType("Purchase", rowKey, value, id, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addCoin("Purchase", {
			fieldType: rowKey,
			id: id,
			value: value
		})
	}

	const removeCoin = (id, index, isDropToDelete) => {
		props.removeCoinFieldType("Purchase", rowKey, index, id, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.removeCoin("Purchase", {
			fieldType: rowKey,
			id: id,
			index: index,
			isDropToDelete: isDropToDelete
		})
	}

	const isDraggingHandler = (type, isDragging, data) => {
		let dataObj = {
			teamId: window.teamId,
			sessionId: window.sessionId,
			playerId: window.playerId,
			playerName: window.playerName,
			alias: "Purchase",
			isDragging: isDragging,
			type: type,
			fieldType: rowKey
		}
		dataObj = { ...data, ...dataObj }

		console.log(dataObj);
		window.socket.emit('item-dragging', dataObj);
	}

	const showContainerProperties = (id) => {
		props.openContainerPropertiesWithTitle('FIELD', 'Purchase', rowKey, id);
	}

	const renderNumber = (title) => {
		switch(title) {
			case "Standard-row":
				return (
					<NumberBox number={2} />
				)
			case "Compact-row":
				return (
					<NumberBox number={1} />
				)
			case "Plus-row":
				return (
					<NumberBox number={3} />
				)
			case "Luxus-row":
				return (
					<NumberBox number={4} />
				)
			default:
				break;
		}
	}

	return (
		<Row className={`Purchase-row ${ className }`}>
			<Col className="Block-center">
				{ renderNumber(className) }
			</Col>
			<Col xs={4} onClick={ () => showContainerProperties(0) }>
				{
					(rowKey && 
						<DroppableField
							canEdit={canEdit}
							id={0}
							class={ dropFieldClassNames.Purchase }
							data={ props.purchase[rowKey][0] }
							isHorizontal={ true }
							appendContainer={ appendContainer }
							removeContainer={ removeContainer }
							addCoin={ addCoin }
							removeCoin={ removeCoin }
							isDraggingHandler={ isDraggingHandler }
						/>
					)
				}
			</Col>
			<Col xs={4} onClick={ () => showContainerProperties(1)}>
				{
					(rowKey && 
						<DroppableField
							canEdit={canEdit}
							id={1}
							class={ dropFieldClassNames.Purchase }
							data={ props.purchase[rowKey][1] }
							isHorizontal={ true }
							appendContainer={ appendContainer }
							removeContainer={ removeContainer }
							addCoin={ addCoin }
							removeCoin={ removeCoin }
							isDraggingHandler={ isDraggingHandler }
						/>
					)
				}
			</Col>
		</Row>
	)
}

const mapStateToProps = (state) => {
	return {
		purchase: state.board.CenterBlock.Purchase
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseRow);