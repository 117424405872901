import React from "react";
import { useDrag } from 'react-dnd';
import {Image} from "react-bootstrap";

// Constants
import items from "../../../utils/items";

// Images
import green from "../../../assets/images/green-card.png";
import blue from "../../../assets/images/blue-card.png";
import yellow from "../../../assets/images/yellow-card.png";
import red from "../../../assets/images/red-card.png";


export default function MainDraggableProductCard(props) {
	const [, drag] = useDrag({
		item: { type: items.productLbl, value: props.value, color: props.color },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let droppedItem = monitor.getDropResult();
			if (droppedItem !== null && droppedItem.type !== undefined) {
				if (monitor.didDrop()) {
					props.decreaseStarterKit("production", dropResult.value, dropResult.color);
				}
			}
		}
	});

	const canDrag = (props.canEdit) ? drag : null
	console.log(props.color)
	return (
		<>
			<div className="productCardDraggable" ref={canDrag}>
				{(
					(props.color === "standard" &&
						<Image className="productCardDraggableImg" src={green} />)
					|| (props.color === "compact" &&
						<Image className="productCardDraggableImg" src={yellow} />)
					|| (props.color === "plus" &&
						<Image className="productCardDraggableImg" src={blue} />)
					|| (props.color === "luxus" &&
						<Image className="productCardDraggableImg" src={red} />)
				)}
				<>
					{
						(props.value > 0) ?
							<div className="productCardValue">{ props.value }</div>
							:
							null
					}
				</>
			</div>
		</>
	);
}

/*<DragPreviewImage connect={preview} src={previewImg} /> */