import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";

// Components
import DroppableDirectCostField from "./Fields/DroppableDirectCostField";

const BottomRightBlockRowField = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	const appendContainer = (coins, hasCard, newCardValue, cardColor, oldCardValue, id) => {
		props.appendContainer(props.id, coins, hasCard, newCardValue, cardColor, oldCardValue, id);
	}

	const removeContainer = () => {
		props.removeContainer(props.id);
	}

	const showContainerProperties = () => {
		props.showContainerProperties(props.id);
	}

	return (
		<Col className="Droppable-container-margin" onClick={showContainerProperties}>
			<DroppableDirectCostField
				canEdit={canEdit}
				data={props.object}
				appendContainer={appendContainer}
				removeContainer={removeContainer}
			/>
		</Col>
	)
}

export default BottomRightBlockRowField;