import checkListstate from "./checklist-init-state";
import initState from "./simflex-session-init-state";

const reducer = (state = initState, action) => {

	if (action.type === "UPDATE_YEAR") {
		return {
			...state,
			year: action.value,
			formSelectedYear: action.value
		}
	}else if (action.type === "UPDATE_TEAM_NAME") {
		return {
			...state,
			teamName: action.value
		}
	}else if (action.type === "UPDATE_COUNTER") {
		return {
			...state,
			timer: action.value
		}
	}else if (action.type === "UPDATE_FILES") {
		return {
			...state,
			files: action.value
		}
	}else if (action.type === "ADD_FILE") {
		let files = state.files;
		files.push(action.value);

		return {
			...state,
			files: files
		}
	}else if (action.type === "REMOVE_FILE") {
		let listOfFiles = state.files.filter(fileObj => fileObj._id !== action.id)
		
		return {
			...state,
			files: listOfFiles
		}
	}else if (action.type === "ADD_MESSAGE") {
		let messages = state.messages;
		messages.push(action.value)

		return {
			...state,
			messages: messages,
			unreadCount: state.unreadCount + 1
		}
	}else if (action.type === "MESSAGES_LIST") {
		let messages = state.messages;
		let newMessages = messages.concat(action.value)

		return {
			...state,
			messages: newMessages
		}
	}else if (action.type === "NEW_MESSAGE") {
		return {
			...state,
			unreadCount: state.unreadCount + 1
		}
	}else if(action.type === "TOGGLE_CHAT") {
		return {
			...state,
			isChatOpened: action.isOpened,
			unreadCount: (action.isOpened) ? 0 : state.unreadCount
		}
	}else if(action.type === "OPEN_FORM") {
		return {
			...state,
			openedForm: action.value
		}
	}else if(action.type === "CHANGE_LANGUAGE") {
		return {
			...state,
			selectedLang: action.value
		}
	}else if (action.type === "RESTORE_FORM_STATUS") {
		return {
			...state,
			formStatus: action.data
		}
	}else if (action.type === "UPDATE_FORM_STATUS") {
		return {
			...state,
			formStatus: {
				...state.formStatus,
				[action.data.form]: action.data.status
			}
		}
	}else if (action.type === "CHANGE_FORM_YEAR") {
		return {
			...state,
			formSelectedYear: action.year
		}
	}else if (action.type === "RESTORE_LOCALIZATION") {
		return {
			...state,
			localizations: action.localizations
		}
	}

	state.checkList = checkListstate;
	return state;
}

export default reducer;