import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {connect} from "react-redux";

// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";
import * as RealtimeConnection from "../../RealtimeConnection";

// Components
import SalesRow from "./SalesRow";

const SalesBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])


	const appendPin = (title, speed, id) => {
		props.addPin("Sales", title, speed, id)

		// Update Socket
		RealtimeConnection.addPin("Sales", {
			title: title,
			speed: speed,
			id: id
		})
	}

	const removePin = (title, speed, id, isDropToDelete) => {
		props.removePin("Sales", title, speed, id)

		// Update Socket
		RealtimeConnection.removePin("Sales", {
			title: title,
			speed: speed,
			id: id,
			isDropToDelete: isDropToDelete
		})
	}

	return (
		<Container>
			{
				Object.keys(props.sales).map( (key, index) =>
					<SalesRow
						canEdit={canEdit}
						key={index}
						title={key}
						object={props.sales[key]}
						appendPin={appendPin}
						removePin={removePin}
					/>
				)
			}
		</Container>
	)
}

const mapStateToProps = (state) => {
	return {
		sales: state.board.CenterBlock.Sales
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(SalesBlock);