import React, { useEffect, useState } from "react";
import {Col, Row} from "react-bootstrap";

import Translate from "../../../../utils/Translate"

// Components
import ProductionBlockSection from "./ProductionBlockSection";

const ProductionBlock = (props) => {

	const [canEdit, setCanEdit] = useState(false)

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	return (
		<div className="Production-container">
			<Row>
				<Col>
					<div className="Block-title-bold-text Block-title-text-size Same-line">
						<Translate alias="Board" word="Production" />
					</div>
				</Col>
			</Row>
			<Row>
				<div className="Production-column">
					<ProductionBlockSection canEdit={canEdit} id={0} title="National" />
				</div>
				<div className="Production-column">
					<ProductionBlockSection canEdit={canEdit} id={1} title="National" />
				</div>
				<div className="Production-column">
					<ProductionBlockSection canEdit={canEdit} id={2} title="National" />
				</div>
				<div className="Production-column">
					<ProductionBlockSection canEdit={canEdit} id={3} title="International" />
				</div>
				<div className="Production-column">
					<ProductionBlockSection canEdit={canEdit} id={4} title="International" />
				</div>
			</Row>
			<Row>
				<div className="Production-column">
					<ProductionBlockSection canEdit={canEdit} id={5} title="International" />
				</div>
				<div className="Production-column">
					<ProductionBlockSection canEdit={canEdit} id={6} title="Inter-continental 1" />
				</div>
				<div className="Production-column">
					<ProductionBlockSection canEdit={canEdit} id={7} title="Inter-continental 1" />
				</div>
				<div className="Production-column">
					<ProductionBlockSection canEdit={canEdit} id={8} title="Inter-continental 2" />
				</div>
				<div className="Production-column">
					<ProductionBlockSection canEdit={canEdit} id={9} title="Inter-continental 2" />
				</div>
			</Row>
		</div>
	)
}

export default ProductionBlock;